<script>
  // core components
  import CardStats from "../../components/Cards/CardStats.svelte";
</script>

<!-- Header -->
<div class="relative bg-emerald-500 md:pt-32 pb-32 pt-12 ">
  <div class="px-4 md:px-10 mx-auto w-full ">
    <div>
      <!-- Card stats -->

    </div>
  </div>
</div>
