<script>
  import { writable } from "svelte/store";
  import { onMount } from 'svelte';
  import { getData, getFirstCourseData } from "../../store/db";
  import TableDropdown from "../../components/Dropdowns/TableDropdown.svelte";
  import { DownloadUserData } from "../../locationStore";

  let students = writable([]);
  let courses = {
    English: [
      { name: "Elementary C1", start_date: "12/24/2024", end_date: "12/24/2024", status: "active", course: "English" },
    ],
    ACCA: [
      { name: "ACCA C1", start_date: "12/24/2024", end_date: "12/24/2024", status: "active", course: "ACCA" },
    ],
  };

  let active_course = writable("All courses");
  let active_class = writable("");
  let active_status = writable("All");
  let start_date = writable("2024-01-01");
  let end_date = writable("2024-12-30");
  let searchQuery = writable(""); // Search query state

  let classOptions = [];
  let filteredStudents;

  async function fetchData() {
    try {
      const courses_date = await getFirstCourseData();
      courses = courses_date;
      const data = await getData();
      students.set([...data]); // Trigger reactivity by spreading the array
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }

  onMount(() => {
    fetchData();
  });

  // Class options filtering based on active course
  $: {
    if ($active_course === "All courses") {
      classOptions = Object.values(courses).flat().map(c => c.name);
    } else {
      classOptions = courses[$active_course]?.map(c => c.name) || [];
    }
  }

  // Filtering students based on active filters and search query
  $: filteredStudents = $students.filter(student => {
    let registeredDate = new Date(student.registeredDate);
    let startDate = new Date($start_date);
    let endDate = new Date($end_date);

    let matchesSearch = $searchQuery === "" ||
      student.name.toLowerCase().includes($searchQuery.toLowerCase()) ||
      student.phoneNumber.toString().includes($searchQuery) ||
      student.email.toLowerCase().includes($searchQuery.toLowerCase());

    return matchesSearch &&
      ($active_course === "All courses" || student.Course === $active_course) &&
      ($active_class === "" || student.classLevel === $active_class) &&
      ($active_status === "All" || student.status === $active_status) &&
      (!startDate || registeredDate >= startDate) &&
      (!endDate || registeredDate <= endDate);
  });

  function export_fun() {
    DownloadUserData(filteredStudents);
  }

  function balance(paid_amount, total_amount) {
    return total_amount - paid_amount;
  }

  function calculateProgress(watchedsofar) {
    if (!watchedsofar) return "0%";
    const [watched, total] = watchedsofar.split("/").map(Number);
    if (total === 0) return "0%";
    return `${((watched / total) * 100).toFixed(0)}%`;
  }
  function getStatusColor(status) {
    return status === "deactive" ? "red" : "green"; // Return red for deactive, green otherwise
  }
</script>

<div class="container mx-auto px-4 mb-16">
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="mt-4 p-4">
          <label for="courseSelector">Select a course:</label>
          <select id="courseSelector" bind:value={$active_course} class="form-select">
            <option value="All courses">All courses</option>
            {#each Object.keys(courses) as course}
              <option value={course}>{course}</option>
            {/each}
          </select>
        </div>
        <div class="mt-4 p-2">
          <label for="classSelector">Select a class:</label>
          <select id="classSelector" bind:value={$active_class} class="form-select">
            <option value="">All classes</option>
            {#each classOptions as classOption}
              <option value={classOption}>{classOption}</option>
            {/each}
          </select>
        </div>
        <div class="mt-4 p-4">
          <label for="statusSelector">Select a status:</label>
          <select id="statusSelector" bind:value={$active_status} class="form-select">
            <option value="All">All</option>
            <option value="active">Active</option>
            <option value="deactive">Deactive</option>
          </select>
        </div>
        <div class="mt-4">
          <label for="startDate">By date:</label>
          <input type="date" id="startDate" bind:value={$start_date} class="form-input">
          <label for="endDate">:</label>
          <input type="date" id="endDate" bind:value={$end_date} class="form-input">
        </div>
        <!-- Search bar -->
        <div class="mt-4 p-4 flex">
          <i class="fas fa-search mt-2 mr-2"></i>
          <input type="text" placeholder="Search by name, phone, or email..." class="form-input" bind:value={$searchQuery} />
        </div>
      </div>
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-lg text-blueGray-700">All Students</h3>
        </div>
        <button on:click={export_fun} class="bg-indigo-500 text-white w-30 h-12 px-2 py-2 rounded form-input" type="button">
          EXPORT
        </button>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th class="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
              Student Name
            </th>
            <th class="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
              Phone Number
            </th>
            <th class="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
              Email
            </th>
            <th class="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
              Balance
            </th>
            <th class="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
              Status
            </th>
            <th class="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
              Registered Date
            </th>
            <th class="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
              Completed so far
            </th>
            <th class="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"></th>
          </tr>
        </thead>
        <tbody>
          {#each filteredStudents as student, index}
            <tr>
              <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                <span class="ml-3 font-bold text-blueGray-600">{index + 1}: {student.name}</span>
              </td>
              <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{student.phoneNumber}</td>
              <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{student.email}</td>
              <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style="color: red;">${balance(student.paidFee,student.totalFee)} USD</td>
              <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {student.status}
              </td>
              
              <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{student.registeredDate}</td>
              <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <div class="overflow-hidden h-2 text-xs flex rounded bg-red-200">
                    <div class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500" style="width: {calculateProgress(student.watchedSoFar)}"></div>
                  </div>
                  <span>{student.watchedSoFar||"0/0"}</span>
              </td>
              
              <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                <TableDropdown student={student} />
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
    </div>
  </div>
</div>
