<script>
  import { writable } from "svelte/store";
  import { onMount } from 'svelte';
  import { fetchLectures, getData,saveAttendanceData } from "../../store/db";

  // Data Variables
  let lectures = [];
  let students = [];
  let filteredLectures = [];
  let filteredStudents = [];

  // Reactive Variables
  let selectedCourse = writable(null);
  let selectedClassLevel = writable(null);
  let selectedLecture = writable(null);
  let searchActive = false;

  async function fetchData() {
    try {
      // Fetch lectures and students
      lectures = await fetchLectures();
      students = await getData();
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }

  // Function to filter lectures by course
  $: {
    if ($selectedCourse) {
      filteredLectures = lectures.filter(lecture => lecture.course === $selectedCourse);
    } else {
      filteredLectures = [];
    }
  }

  // Function to filter students by classLevel and lecture name
  function search() {
    if ($selectedLecture) {
      filteredStudents = students.filter(student => student.classLevel === $selectedLecture.classLevel);
      console.log(filteredStudents);
      searchActive = true;
    }
  }


async function saveAttendance() {
  let attendanceData = {
    Lecture: $selectedLecture ? `${$selectedLecture.name} - ${$selectedLecture.classLevel}` : "Unknown",
    TotalStudents: filteredStudents.length,
    TotalPresents: 0,
    TotalAbsents: 0,
    TotalLates: 0,
    StudentAttendances: []
  };

  filteredStudents.forEach(student => {
    // Default to 'present' if no attendance is selected
    if (!student.attendance) {
      student.attendance = 'present';
    }

    // Count the totals
    if (student.attendance === 'present') {
      attendanceData.TotalPresents++;
    } else if (student.attendance === 'absent') {
      attendanceData.TotalAbsents++;
    } else if (student.attendance === 'late') {
      attendanceData.TotalLates++;
    }

    attendanceData.StudentAttendances.push({
      name: student.name,
      attendance: student.attendance
    });
  });

  console.log("Attendance Data:", attendanceData);

  try {
    await saveAttendanceData(attendanceData);
    alert('Attendance successfully saved!');
  } catch (error) {
    console.error('Error saving attendance data:', error);
  }
}


  // Fetch data on component mount
  onMount(() => {
    fetchData();
  });
</script>

<!-- Filter and Action Buttons in One Row -->
<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
  <div style="margin-bottom: 20px; padding: 10px; border: 1px solid #e5e5e5; border-radius: 8px;">
    <h2 style="font-size: 18px; margin-bottom: 15px;">Subject Attendance</h2>

    <!-- Filters in One Row -->
    <div style="display: flex; gap: 10px; margin-bottom: 15px;">

      <!-- Course Selector -->
      <div style="flex: 1;">
        <label style="display: block; font-size: 14px; margin-bottom: 5px;">Course</label>
        <select bind:value={$selectedCourse} style="width: 100%; padding: 8px; border-radius: 4px; border: 1px solid #ccc;">
          <option value={null}>Select a Course</option>
          {#each Array.from(new Set(lectures.map(l => l.course))) as course}
            <option value={course}>{course}</option>
          {/each}
        </select>
      </div>

      <!-- Class Level Selector (disabled if no course selected) -->
      <div style="flex: 1;">
        <label style="display: block; font-size: 14px; margin-bottom: 5px;">Class Level</label>
        <select bind:value={$selectedClassLevel} disabled={!$selectedCourse} style="width: 100%; padding: 8px; border-radius: 4px; border: 1px solid #ccc;">
          <option value={null}>Select a Class Level</option>
          {#each Array.from(new Set(filteredLectures.map(l => l.classLevel))) as classLevel}
            <option value={classLevel}>{classLevel}</option>
          {/each}
        </select>
      </div>

      <!-- Lecture Selector (disabled if no class level selected) -->
      <div style="flex: 1;">
        <label style="display: block; font-size: 14px; margin-bottom: 5px;">Session</label>
        <select bind:value={$selectedLecture} disabled={!$selectedClassLevel} style="width: 100%; padding: 8px; border-radius: 4px; border: 1px solid #ccc;">
          <option value={null}>Select a Session</option>
          {#each filteredLectures as lecture (lecture.id)}
            <option value={lecture}>{lecture.name}</option>
          {/each}
        </select>
      </div>

      <!-- Search Button (shown only if a session is selected) -->
      {#if $selectedLecture}
      <div style="text-align: right;" class="mt-8">
        <button on:click={search} style="padding: 8px 12px; background-color: #28a745; color: white; border: none; border-radius: 4px;">
          <i class="fas fa-search"></i> Search
        </button>
      </div>
      {/if}

    </div>

    <!-- Filtered Student List -->
    {#if searchActive && filteredStudents.length > 0}
    <div>
      <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
        <thead>
          <tr style="background-color: #f5f5f5;">
            <th style="text-align: left; padding: 10px;">#</th>
            <th style="text-align: left; padding: 10px;">Student Name</th>
            <th style="text-align: left; padding: 10px;">Attendance</th>
          </tr>
        </thead>
        <tbody>
          {#each filteredStudents as student, index}
          <tr>
            <td style="padding: 10px;">{index + 1}</td>
            <td style="padding: 10px;">{student.name}</td>
            <td style="padding: 10px; display: flex; gap: 15px; justify-content: left;">
              <input type="radio" name="attendance-{student.id}" value="present" style="width: 20px; height: 20px; border-radius: 50%; border: 2px solid #ccc; background-color: {student.attendance === 'present' ? 'green' : 'transparent'};" on:change={() => student.attendance = 'present'} />
              <input type="radio" name="attendance-{student.id}" value="absent" style="width: 20px; height: 20px; border-radius: 50%; border: 2px solid #ccc; background-color: {student.attendance === 'absent' ? 'red' : 'transparent'};" on:change={() => student.attendance = 'absent'} />
              <input type="radio" name="attendance-{student.id}" value="late" style="width: 20px; height: 20px; border-radius: 50%; border: 2px solid #ccc; background-color: {student.attendance === 'late' ? 'yellow' : 'transparent'};" on:change={() => student.attendance = 'late'} />
            </td>
          </tr>
          {/each}
        </tbody>
      </table>
    </div>

    <!-- Centered Save Button -->
    <div style="text-align: center; margin-top: 20px;">
      <button on:click={saveAttendance} style="padding: 8px 12px; background-color: #007bff; color: white; border: none; border-radius: 4px;">
        Save
      </button>
    </div>

    {:else if searchActive}
    <p>No students found for the selected lecture.</p>
    {/if}

  </div>
</div>
