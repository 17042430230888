<script>
  import { saveStudent, getFirstCourseData, findusername, checkStudentExists } from "../../store/db";
  import { onMount } from 'svelte';
  import { getUserEmail } from "../../store/store";

  let email = '';
  let student = {
    name: "",
    status: "active",
    phoneNumber: "",
    email: "",
    registeredDate: "",
    lastPaidTime: "",
    lastPaidAmount: "",
    paidFee: "",
    totalFee: "",
    fee_status: "paid",
    classLevel: "",
    Course: "",
    registeredBy: ""
  };
  let courses = {
    English: [
      { name: "Elementary C1", start_date: "12/24/2024", end_date: "12/24/2024", status: "active", course: "English" },
      { name: "Intermediate C2", start_date: "12/24/2024", end_date: "12/24/2024", status: "active", course: "English" },
      { name: "Advanced C3", start_date: "12/24/2024", end_date: "12/24/2024", status: "active", course: "English" },
      { name: "Advanced C4", start_date: "12/24/2024", end_date: "12/24/2024", status: "active", course: "English" }
    ],
    ACCA: [
      { name: "ACCA C1", start_date: "12/24/2024", end_date: "12/24/2024", status: "active", course: "ACCA" },
      { name: "ACCA C2", start_date: "12/24/2024", end_date: "12/24/2024", status: "active", course: "ACCA" }
    ],
  };
  let Defoult_student = {
    name: "",
    status: "active",
    phoneNumber: "",
    email: "",
    registeredDate: "",
    lastPaidTime: "",
    lastPaidAmount: "",
    paidFee: "",
    totalFee: "",
    fee_status: "paid",
    classLevel: "",
    Course: "",
    registeredBy: ""
  };

  function addStudent() {
    // Check if any of the input fields are empty
    if (
      !student.name ||
      !student.email ||
      !student.phoneNumber ||
      !student.classLevel ||
      !student.totalFee ||
      !student.Course ||
      !student.paidFee ||
      !student.registeredDate
    ) {
      alert("Please fill in all fields.");
      return;
    }

    student.lastPaidTime = student.registeredDate;
    student.lastPaidAmount = parseFloat(student.paidFee.replace(/[^0-9.-]+/g,""));
    student.paidFee = parseFloat(student.paidFee.replace(/[^0-9.-]+/g,""));
    student.totalFee = parseFloat(student.totalFee.replace(/[^0-9.-]+/g,""));
    student.registeredBy = email;

    checkStudentExists(student.email, student.phoneNumber)
      .then(result => {
        if (!result) {
          console.log(student);
          saveStudent(student);
          alert("We saved a new student.");
          student = { ...Defoult_student };
        } else {
          alert("Student already exists.");
        }
      })
      .catch(error => console.error("Error checking student existence:", error));
  }

  async function fetchData() {
    try {
      const class_date = await getFirstCourseData();
      courses = class_date;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }

  onMount(async () => {
    try {
      email = await findusername();
    } catch (error) {
      console.error(error);
    }
    fetchData();
  });
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-blueGray-700 text-xl font-bold">New Student</h6>
      <button on:click={addStudent} class="bg-red-400 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">
        Add
      </button>
    </div>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form>
      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">Student Information</h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-username">Complete Name</label>
            <input id="grid-username" type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="cqani shiine" bind:value={student.name} />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-email">Email Address</label>
            <input id="grid-email" type="email" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="cqani@example.com" bind:value={student.email} />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-first-name">Phone Number</label>
            <input id="grid-first-name" type="number" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="+25290*****" bind:value={student.phoneNumber} />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Course</label>
          <select bind:value={student.Course} class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
            <option value="">-- Choose Course --</option>
            {#each Object.keys(courses) as course}
              <option value={course}>{course}</option>
            {/each}
          </select>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Class Level</label>
          <select bind:value={student.classLevel} class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
            {#if courses[student.Course]}
            <option value="">-- Select Class --</option>
              {#each courses[student.Course] as classItem}
                <option value={classItem.name}>{classItem.name}</option>
              {/each}
            {:else}
              <option disabled selected>-- Select Course --</option>
            {/if}
          </select>
        </div>
      </div>
      <hr class="mt-6 border-b-1 border-blueGray-300" />
      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">Fee Information</h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-12/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-address">Total Fee Needed</label>
            <input id="grid-address" type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="55.00" bind:value={student.totalFee} on:input={e => student.totalFee = e.target.value.replace(/[^0-9.]/g, '')} />
          </div>
        </div>
        <div class="w-full lg:w-12/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-city">Paid Fee</label>
            <input id="grid-city" type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="34.00" bind:value={student.paidFee} on:input={e => student.paidFee = e.target.value.replace(/[^0-9.]/g, '')} />
          </div>
        </div>
      </div>
      <hr class="mt-6 border-b-1 border-blueGray-300" />
      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">Other Information</h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-12/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-address">Registration Date</label>
            <input id="grid-address" type="date" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="12/24/2024" bind:value={student.registeredDate} />
          </div>
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-address">Registration By</label>
            <input id="grid-address" type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="loading...." bind:value={email} disabled />
          
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
