<script>
  // core components
  import CardTable from "../../components/Cards/Lead_mangemnt.svelte";
  import { checkPermissions } from "../../lib/checkPermissions";
  import { onMount } from "svelte";
  let hasAccess = false;

  onMount(async () => {
    hasAccess = await checkPermissions('maps', '/admin/tables');
  });
    export let location;
</script>
{#if hasAccess}
<div class="flex flex-wrap mt-4">
  <div class="w-full mb-12 px-4">
    <CardTable />
  </div>
</div>
{/if}
