<script>
    import { saveCourse } from "../../store/db"
    let new_course = {
        name :"",
        created_at :""
    }

    async function saveClassindb(course_name) {
    try {
        saveCourse(course_name); 
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }
    function add_course(){
        console.log("new course",new_course)
        if (
      !new_course.name ||
      !new_course.created_at
  ){
      alert("Please fill in all fields.");
      return;
  }
  saveClassindb(new_course.name)
    }


  </script>
  
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">New Course</h6>
        <button class="bg-red-400 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" on:click={add_course}>
          Add it
        </button>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          course information
        </h6>
        <div class="flex flex-wrap">
          
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="className">
                Course name
              </label>
              <input id="className" type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="English"  bind:value={new_course.name}/>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="createdAt">
                Created at 
              </label>
              <input id="createdAt" type="date" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="12/2/2024" bind:value={new_course.created_at}/>
            </div>
          </div>

      </form>
    </div>
  </div>
  