<script>
    import { writable } from "svelte/store";
    import { onMount } from 'svelte';
    import { fetchLectures, findusername } from "../../store/db"; // Import findusername
    import TableDropdown from "../../components/Dropdowns/TableDropdown.svelte";
    import CardSocialTraffic from "../../components/Cards/myTime_table.svelte";  

    let lectures = []; // Initialize as an empty array
    let active_course = writable("All courses");
    let active_class = writable("");
    let active_teacher = writable("All teachers");
    let username = ""; // Store the user's name
  
    let courses = [];
    let classes = [];
    let teachers = [];
  
    onMount(async () => {
      try {
        // Get lectures
        const response = await fetchLectures();
        lectures = Array.isArray(response) ? response : []; // Ensure it's an array
        
        // Get the username
        username = await findusername();
        
        // Dynamically extract unique courses, classes, and teachers
        const coursesSet = new Set();
        const classesSet = new Set();
        const teachersSet = new Set();
  
        lectures.forEach(lecture => {
          coursesSet.add(lecture.course);
          classesSet.add(lecture.classLevel);
          teachersSet.add(lecture.teacher);
        });
  
        courses = Array.from(coursesSet);
        classes = Array.from(classesSet);
        teachers = Array.from(teachersSet);
      } catch (error) {
        console.error("Failed to load lectures:", error);
        lectures = []; // Set lectures to an empty array on error
      }
    });
  
    // Computed property for filtered lectures
    let filteredLectures;
    $: {
      filteredLectures = lectures.filter(lecture => {
        return (
          ($active_course === "All courses" || lecture.course === $active_course) &&
          ($active_class === "" || lecture.classLevel === $active_class) &&
          ($active_teacher === "All teachers" || lecture.teacher === $active_teacher) &&
          (lecture.teacher === username)
        );
      });
      console.log(filteredLectures);
      
    }
  
    // Function to export lectures if needed
    function exportLectures() {
      // Implement export functionality here if needed
    }
</script>

<div class="container mx-auto px-4 mb-16 w-full">
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <h1 class="text-3xl font-bold text-center mb-6">Lectures Of {username}</h1>
      <div class="flex flex-wrap items-center">
        <div class="mt-4 p-4">
          <label for="courseSelector">Select a course:</label>
          <select id="courseSelector" bind:value={$active_course} class="form-select">
            <option value="All courses">All courses</option>
            {#each courses as course}
              <option value={course}>{course}</option>
            {/each}
          </select>
        </div>
        <div class="mt-4 p-2">
          <label for="classSelector">Select a class:</label>
          <select id="classSelector" bind:value={$active_class} class="form-select">
            <option value="">All classes</option>
            {#each classes as classLevel}
              <option value={classLevel}>{classLevel}</option>
            {/each}
          </select>
        </div>
        <div class="mt-4 p-4">
          <label for="teacherSelector">Select a teacher:</label>
          <select id="teacherSelector" bind:value={$active_teacher} class="form-select">
            <option value="All teachers">All teachers</option>
            {#each teachers as teacher}
              <option value={teacher}>{teacher}</option>
            {/each}
          </select>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
              Lecture Name
            </th>
            <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
              Day
            </th>
            <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
              Time
            </th>
            <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
              Course
            </th>
            <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
              Class Level
            </th>
            <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
              Teacher
            </th>
            <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"></th>
          </tr>
        </thead>
        <tbody>
          {#each filteredLectures as lecture}
            <tr>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style="font-size: 1.1rem; font-weight: 600;">
                {lecture.name}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style="font-size: 1.1rem; font-weight: 600;">
                {lecture.day}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style="font-size: 1.1rem; font-weight: 600;">
                {lecture.startTime} - {lecture.endTime} EST time
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style="font-size: 1.1rem; font-weight: 600;">
                {lecture.course}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style="font-size: 1.1rem; font-weight: 600;">
                {lecture.classLevel}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style="font-size: 1.1rem; font-weight: 600;">
                {lecture.teacher}
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
    </div>
  </div>
  <CardSocialTraffic/>
</div>
