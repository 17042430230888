<script>
  import { getFirstCourseData, findStudentByEmail, updateStudentData, findusername } from "../../store/db";
  import { onMount } from 'svelte';

  let email = '';
  export let user_email = "";
  export let user_phonenumber = "";
  let student_id;
  let student = {
      name: "",
      status: "active",
      phoneNumber: "",
      email: "",
      registeredDate: "",
      lastPaidTime: "",
      lastPaidAmount: 0.00,
      paidFee: 0.0,
      totalFee: 0.00,
      fee_status: "paid", // Set fee_status to "paid"
      classLevel: "",
      Course: "",
      registeredBy: "",
      watchedSoFar: "",  // Add new field for watchedSoFar
  };
  let courses = {
      English: [
          { name: "Elementary C1", start_date: "12/24/2024", end_date: "12/24/2024", status: "active", course: "English" },
          { name: "Intermediate C2", start_date: "12/24/2024", end_date: "12/24/2024", status: "active", course: "English" },
          { name: "Advanced C3", start_date: "12/24/2024", end_date: "12/24/2024", status: "active", course: "English" },
          { name: "Advanced C4", start_date: "12/24/2024", end_date: "12/24/2024", status: "active", course: "English" }
      ],
      ACCA: [
          { name: "ACCA C1", start_date: "12/24/2024", end_date: "12/24/2024", status: "active", course: "ACCA" },
          { name: "ACCA C2", start_date: "12/24/2024", end_date: "12/24/2024", status: "active", course: "ACCA" }
      ],
      // Add more courses and classes as needed
  };

  let Defoult_student = {
      name: "",
      status: "active",
      phoneNumber: "",
      email: "",
      registeredDate: "",
      lastPaidTime: "",
      lastPaidAmount: 0.00,
      paidFee: 0.0,
      totalFee: 0.00,
      fee_status: "paid", // Set fee_status to "paid"
      classLevel: "",
      Course: "",
      registeredBy: "",
      watchedSoFar: "", // Add watchedSoFar to default student object
  };

  function addStudent() {
      if (!student.name || !student.email || !student.phoneNumber) {
          alert("the student has some parts empty");
          return;
      }

      if (student.watchedSoFar === "") {
          alert("Please fill the 'watchedSoFar' field before saving.");
          return;
      }

      const watchedSoFarPattern = /^\d{2}\/\d{2}$/;  // Format validation: "09/40"
      if (!watchedSoFarPattern.test(student.watchedSoFar)) {
          alert("Please enter 'watchedSoFar' in the correct format (e.g., '09/40').");
          return;
      }

      student.lastPaidTime = student.registeredDate;
      student.lastPaidAmount = student.paidFee;
      updateStudentData(student_id, student);
      console.log(student_id);
  }

  async function fetchData() {
      try {
          const class_date = await getFirstCourseData();
          courses = class_date;
      } catch (error) {
          console.error("Error fetching data: ", error);
      }
  }

  onMount(async () => {
      console.log(user_email);

      try {
          email = await findusername();
      } catch (error) {
          console.error(error);
      }

      // Fetch the student only once, when the component mounts
      try {
          const studentDoc = await findStudentByEmail(user_email);
          if (studentDoc) {
              student_id = studentDoc.id;
              student = studentDoc.data;
              if (!student.watchedSoFar) {
                  student.watchedSoFar = ""; // Initialize empty if not present
              }
              console.log(student_id);
          } else {
              console.log("No student found with the given email", user_email);
          }
      } catch (error) {
          console.error("Error finding student:", error);
      }

      // Fetch course data
      fetchData();
  });
</script> 
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">edit student</h6>
        <button on:click={addStudent} class="bg-red-400 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">
          Save it
        </button>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          student Information
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="grid-username"
              >
                complete name
              </label>
              <input
                id="grid-username"
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="cqani shiine"
                bind:value={student.name}
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="grid-email"
              >
                Email address
              </label>
              <input
                id="grid-address"
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="cqanimoha@gmail.com"
                bind:value={student.email}
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="grid-first-name"
              >
                number
              </label>
              <input
                id="grid-address"
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="+252......."
                bind:value={student.phoneNumber}
                disabled
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Course</label>
            <select bind:value={student.Course} class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
              <option value="">-- Choose Course --</option>
              {#each Object.keys(courses) as course}
                <option value={course}>{course}</option>
              {/each}
            </select>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Class Level</label>
            <select bind:value={student.classLevel} class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
              {#if courses[student.Course]}
              <option value="">-- Select Class --</option>
                {#each courses[student.Course] as classItem}
                  <option value={classItem.name}>{classItem.name}</option>
                {/each}
              {:else}
                <option disabled selected>-- Select Course --</option>
              {/if}
            </select>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">status</label>
            <select bind:value={student.status} class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
              <option>active</option> 
              <option>deactive</option>
              <option disabled selected>-- Select status --</option>
            </select>
          </div>
        
        </div>
  
        <hr class="mt-6 border-b-1 border-blueGray-300" />
  
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          fee information
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="grid-address"
              >
                total fee needed
              </label>
              <input
                id="grid-address"
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="$55.00"
                bind:value={student.totalFee}
              />
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="grid-city"
              >
                paid fee
              </label>
              <input
                id="grid-city"
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="27.5"
                bind:value={student.paidFee}
              />
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="grid-postal-code"
              >
                registered date
              </label>
              <input
                id="grid-postal-code"
                type="date"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="12/3/2024"
                bind:value={student.registeredDate}
              />
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-watched-so-far">Watched So Far</label>
            <input
                id="grid-watched-so-far"
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="09/40"
                bind:value={student.watchedSoFar}
            />
        </div>
      </div>
        </div>
  
        <hr class="mt-6 border-b-1 border-blueGray-300" />
  
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Registered by 
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <div
        id="registeredBy"
        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
      >
        {student.registeredDate}
      </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  