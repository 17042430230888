<script>
  import CardTable from "../../components/Cards/CardTable.svelte";
  import { onMount } from 'svelte';
  import { getData,getFirstCourseData } from "../../store/db"
  import { checkPermissions } from "../../lib/checkPermissions";
  import {DownloadUserData} from "../../locationStore"
  let hasAccess = false;

  let students = [
    {
      name: "cqani mohamed",
      fee: "$27.5 USD",
      status: "active",
      registeredBy: "aasiyo",
      websiteCompletion: "60%",
      course: "ACCA",
      class: "ACCA C1"
    },
  ];

  let courses = {
    English: [
      { name: "Elementary C1", start_date: "12/24/2024", end_date:"12/24/2024", status: "active" ,course:"English"},
      { name: "Intermediate C2", start_date: "12/24/2024",end_date:"12/24/2024", status: "active",course:"English" },
      { name: "Advanced C3", start_date: "12/24/2024", end_date:"12/24/2024",status: "active",course:"English" },
      { name: "Advanced C4", start_date: "12/24/2024", end_date:"12/24/2024",status: "active" ,course:"English"}
    ],
    ACCA: [
      { name: "ACCA C1", start_date: "12/24/2024",end_date:"12/24/2024", status: "active" ,course:"ACCA"},
      { name: "ACCA C2", start_date: "12/24/2024", end_date:"12/24/2024",status: "active" ,course:"ACCA"}
    ],
    // Add more courses and classes as needed
  };
  

  let selectedCourse = "";
  let selectedClass = "";
  let selectedStatus = "";
  let filteredStudents = students;
  let filteredClassData = [];

  function filterStudents() {
    if (selectedCourse !== "") {
      filteredStudents = students.filter(student => student.course === selectedCourse);
      console.log("Selected Course:", selectedCourse);

      if (selectedClass !== "") {
        filteredStudents = filteredStudents.filter(student => student.class === selectedClass);
        console.log("Selected Class:", selectedClass);
        filteredClassData = courses[selectedCourse].find(course => course.name === selectedClass) || {};
      } else {
        filteredClassData = [];
      }

      if (selectedStatus !== "") {
        filteredStudents = filteredStudents.filter(student => {
          console.log("Selected Status:", selectedStatus);
          return student.status === selectedStatus;
        });
      }

    } else {
      filteredStudents = students;
      filteredClassData = [];
    }
  }

  function handleCourseChange(event) {
    selectedCourse = event.target.value;
    selectedClass = ""; // Reset selected class when course changes
    filterStudents();
  }

  function handleClassChange(event) {
    selectedClass = event.target.value;
    filterStudents();
  }

  function handleStatusChange(event) {
    selectedStatus = event.target.value;
    filterStudents();
  }

  async function fetchData() {
  try {
    const class_date = await getFirstCourseData()
    courses = class_date
    Object.keys(courses).forEach((courseName) => {
      courses[courseName] = courses[courseName].map(classData => ({
        ...classData,       
        course: courseName 
      }));
    });
    const data = await getData();
    students = data.map(student => ({
      name: student.name,
      fee: `$${student.paidFee} USD`,
      status: student.status,
      registeredBy: student.registeredBy,
      websiteCompletion: "60%", // This value may need to be updated based on your logic
      course: student.Course,
      class: student.classLevel
    }));
    console.log("student looks like this ", students);
    filterStudents(); // Call filterStudents function after fetching data to update the UI
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
}

  onMount(async () => {
    hasAccess = await checkPermissions('tables', '/admin/maps');
    fetchData();
    
  });
  function export_fun() {
    filterStudents()
    DownloadUserData(filteredStudents)
  }
</script>
{#if hasAccess}
<div class="flex flex-wrap mt-10 p-4">
  <div class="w-full mb-12 px-4">
    <div class="flex flex-wrap p-4">
      <!-- Dropdown to select specific course -->
      <div class="mt-4 p-4">
        <label for="courseSelector">Select a course:</label>
        <!-- svelte-ignore a11y-no-onchange -->
        <select id="courseSelector" on:change={handleCourseChange}>
          <option value="">All Courses</option>
          {#each Object.keys(courses) as course}
            <option value={course}>{course}</option>
          {/each}
        </select>
      </div>

      <!-- Dropdown to select specific class -->
      {#if selectedCourse !== ""}
      <div class="mt-4 p-4">
        <label for="classSelector">Select a class:</label>
        <!-- svelte-ignore a11y-no-onchange -->
        <select id="classSelector" on:change={handleClassChange}>
          <option value="">All Classes</option>
          {#each courses[selectedCourse] as classLevel}
            <option value={classLevel.name}>{classLevel.name}</option>
          {/each}
        </select>
      </div>
      {/if}

      <!-- Dropdown to select specific class status -->
      <div class="mt-4 p-4">
        <label for="statusSelector">Select class status:</label>
        <!-- svelte-ignore a11y-no-onchange -->
        <select id="statusSelector" on:change={handleStatusChange}>
          <option value="">All Status</option>
          {#each ["active", "completed", "postponed", "canceled"] as status}
            <option value={status}>{status}</option>
          {/each}
        </select>
      </div>
      <div class="mt-4 p-4">
        <button on:click={export_fun} class="bg-indigo-500 text-white w-30 h-12 px-2 py-2 rounded form-input" type="button">
          EXPORT
        </button>
      </div>
    </div>

    <!-- Display student data -->
    {#if selectedCourse !== "" && selectedClass !== ""}
      <div class="w-full mb-12 px-4">
        <CardTable students={filteredStudents} class_date={filteredClassData}/>
      </div>
    {:else if selectedCourse !== "" && selectedClass === ""}
      {#each courses[selectedCourse] as classLevel}
        {#if selectedStatus === "" || classLevel.status.toLowerCase() === selectedStatus}
          <div class="w-full mb-12 px-4">
            <CardTable students={students.filter(student => student.course === selectedCourse && student.class === classLevel.name)} class_date={classLevel}/>
          </div>
        {/if}
      {/each}
    {:else if selectedCourse === ""}
      {#each Object.keys(courses) as course}
        {#each courses[course] as classLevel}
          {#if selectedStatus === "" || classLevel.status.toLowerCase() === selectedStatus}
            <div class="w-full mb-12 px-4">
              <CardTable students={students.filter(student => student.course === course && student.class === classLevel.name)} class_date={classLevel}/>
            </div>
          {/if}
        {/each}
      {/each}
    {/if}
  </div>
</div>
{/if}

