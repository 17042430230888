<script>
    import { Router, Route } from "svelte-routing";
    import CardSettings from "../../components/Cards/student_edit.svelte";
  
    export let location;
  
    let user_email = "";
    let user_phonenumber = "";
  
    $: {
      if (location) {
        const params = location.pathname.split("/").slice(2); // assuming the structure is /admin/edit/email/number
        user_email = decodeURIComponent(params[1]);
        user_phonenumber = decodeURIComponent(params[2]);
      }
    }
  </script>
  
  <div class="flex flex-wrap justify-content: center">
    <div class="w-full lg:w-8/12 px-4">
      <CardSettings {user_email} {user_phonenumber} />
    </div>
  </div>
  