<script>
  import { onMount } from 'svelte';
  import { checkPaymentStatus } from "../../store/db";
  export let Title = "Paid Students";

  // Define an array of student objects
  export let students = [
    { name: "cqani cali", phonenumber: "254707822046", registeredDate: "14/2/2024", lastPaidTime: "10/3/2024", lastPaidAmount: 10.00, paidFee: 27.5, totalFee: 55.00, status: "paid", classLevel: "elementary c1" },
    { name: "faarax warsame", phonenumber: "252615839740", registeredDate: "20/2/2024", lastPaidTime: "5/3/2024", lastPaidAmount: 20.00, paidFee: 27.5, totalFee: 55.00, status: "paid", classLevel: "intermediate c3" },
    { name: "farxiyo cabdiqaadir warsame", phonenumber: "252907745398", registeredDate: "11/4/2024", lastPaidTime: "", lastPaidAmount: 0.00, paidFee: 27.5, totalFee: 55.00, status: "unpaid", classLevel: "advance c1" },
    { name: "ayaan maxamed faarax", phonenumber: "252907512039", registeredDate: "1/2/2024", lastPaidTime: "3/3/2024", lastPaidAmount: 27.50, paidFee: 27.5, totalFee: 55.00, status: "paid", classLevel: "elementary c2" },
    { name: "cabdille shiine", phonenumber: "252615135373", registeredDate: "1/2/2024", lastPaidTime: "6/3/2024", lastPaidAmount: 22.50, paidFee: 22.5, totalFee: 45.00, status: "paid", classLevel: "elementary c1" }
  ];

  function balance(paid_amount, total_amount) {
    return total_amount - paid_amount;
  }

  // Use reactive statements to recalculate totals when `students` changes
  $: totalPaid = students.reduce((sum, student) => sum + Number(student.paidFee), 0).toFixed(2);
  $: totalBalance = students.reduce((sum, student) => sum + balance(student.paidFee, student.totalFee), 0).toFixed(2);
  $: totalFee = students.reduce((sum, student) => sum + Number(student.totalFee), 0).toFixed(2);
  
  function formatCurrency(value) {
    return Number(value).toFixed(2);
  }
</script>

<div style="font-family: Arial, sans-serif;">
  <div style="border-bottom: 2px solid #ddd; padding-bottom: 10px; margin-bottom: 20px;">
    <h3 style="font-size: 1.5rem; color: #333;">{Title}</h3>
  </div>
  <div>
    <table style="width: 100%; border-collapse: collapse;">
      <thead>
        <tr>
          <th style="padding: 10px; border: 1px solid #ddd; text-align: left; background-color: #f2f2f2;">Student Name</th>
          <th style="padding: 10px; border: 1px solid #ddd; text-align: left; background-color: #f2f2f2;">Student Number</th>
          <th style="padding: 10px; border: 1px solid #ddd; text-align: left; background-color: #f2f2f2;">Last Paid Time</th>
          <th style="padding: 10px; border: 1px solid #ddd; text-align: left; background-color: #f2f2f2;">Total Fee</th>
          <th style="padding: 10px; border: 1px solid #ddd; text-align: left; background-color: #f2f2f2;">Paid Amount</th>
          <th style="padding: 10px; border: 1px solid #ddd; text-align: left; background-color: #f2f2f2;">Balance</th>
          <th style="padding: 10px; border: 1px solid #ddd; text-align: left; background-color: #f2f2f2;">Fee Status</th>
        </tr>
      </thead>
      <tbody>
        {#each students as student ,index}
          <tr>
            <td style="padding: 10px; border: 1px solid #ddd; text-align: left;"><span class="ml-3 font-bold text-blueGray-600">{index + 1}</span>:{student.name}</td>
            <td style="padding: 10px; border: 1px solid #ddd; text-align: left;">{student.phonenumber}</td>
            <td style="padding: 10px; border: 1px solid #ddd; text-align: left;">{student.lastPaidTime}</td>
            <td style="padding: 10px; border: 1px solid #ddd; color: blue;">${formatCurrency(student.totalFee)} USD</td>
            <td style="padding: 10px; border: 1px solid #ddd; color: blue;">${formatCurrency(student.paidFee)} USD</td>
            <td style="padding: 10px; border: 1px solid #ddd; color: blue;">${formatCurrency(balance(student.paidFee, student.totalFee))} USD</td>
            <td style="padding: 10px; border: 1px solid #ddd;">
              {student.fee_status}
            </td>
          </tr>
        {/each}
      </tbody>
      <tfoot>
        <tr>
          <th colspan="3" style="padding: 10px; border: 1px solid #ddd; text-align: left; background-color: #f2f2f2;">Totals</th>
          <th style="padding: 10px; border: 1px solid #ddd; color: blue;text-align: left;">${totalFee} USD</th>
          <th style="padding: 10px; border: 1px solid #ddd; color: blue;text-align: left;">${totalPaid} USD</th>
          <th style="padding: 10px; border: 1px solid #ddd; color: blue;text-align: left;">${totalBalance} USD</th>
          <th style="padding: 10px; border: 1px solid #ddd;"></th>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
