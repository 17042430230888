<script>
    import Fee_report from './Fee_management.svelte'
    import StudentsReport from '../../components/Cards/students_card.svelte';
    import { checkPermissions } from "../../lib/checkPermissions";
    import { onMount } from 'svelte';
    import {updateFeeStatus } from "../../store/db"
  let hasAccess = false;
    
    let showFinancialReport = false;
    let showStudentReport = false;
    let buttonShow = true
  
    function showFinancial() {
      showFinancialReport = true;
      buttonShow = false;
    }
    function showstudent() {
      showStudentReport = true;
      buttonShow = false;
    }
    onMount(async () => { 
    updateFeeStatus()
    hasAccess = await checkPermissions('feeRegistration', '/admin/maps');
  });
</script>
  
<div >
  {#if buttonShow}
    <div class="pt-32"></div>
    <div class="flex flex-col"> <!-- Removed width class and centered alignment -->
      <button on:click={showFinancial} class="text-left px-4 py-2 mb-2 ml-2 bg-blue-500 text-black rounded-md shadow-md hover:bg-blue-600">Financial Report</button>
      <button on:click={showstudent} class="text-left px-4 py-2 mb-2 ml-2 bg-blue-500 text-black rounded-md shadow-md hover:bg-blue-600">Student Report</button>
      <button class="text-left px-4 py-2 mb-2 ml-2 bg-blue-500 text-black rounded-md shadow-md hover:bg-blue-600">Class Report</button>
    </div>
  {:else if showFinancialReport}
    <Fee_report/>
  {:else if showStudentReport}
    <StudentsReport/>
  {/if}
</div>
