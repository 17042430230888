<!-- Admin.svelte -->
<script>
  import { Router, Route } from "svelte-routing";
  import { getUserById } from "../store/db";
  import { printLoggedInUserUID } from "../store/store";

  // components for this layout
  import AdminNavbar from "../components/Navbars/AdminNavbar.svelte";
  import Sidebar from "../components/Sidebar/Sidebar.svelte";
  import HeaderStats from "../components/Headers/HeaderStats.svelte";
  import FooterAdmin from "../components/Footers/FooterAdmin.svelte";

  // pages for this layout
  import Dashboard from "../views/admin/Dashboard.svelte";
  import Settings from "../views/admin/Settings.svelte";
  import edit_students from "../views/admin/edit_student.svelte";
  import Tables from "../views/admin/Tables.svelte";
  import Maps from "../views/admin/Maps.svelte";
  import breview from "../views/admin/breview.svelte";
  import Class_registration from "../views/admin/class_creation.svelte";
  import Course_registration from "../views/admin/course_creation.svelte";
  import Fee_management from "../views/admin/Fee_management.svelte";
  import Fee_registration from "../views/admin/Fee_registration.svelte";
  import Reports from "../views/admin/reports.svelte";
  import Users from "../views/admin/users.svelte";
  import Leads from "../views/admin/Lead_Creation.svelte";
  import Leads_Nurturing from "../views/admin/Leads_Nurturing.svelte";
  import calender from "../views/admin/calender.svelte";
  import Lectures from "../views/admin/Lectures.svelte";
  import MyLectures from "../views/admin/myLectures.svelte";
  import edit_leads from "../views/admin/edit_leads.svelte";
  import attendence from "../views/admin/attendence.svelte";
  import reportattendence from "../views/admin/reportattendence.svelte";
  import deactiveStudents from "../views/admin/deactiveStudents.svelte";
  
  export let location;
</script>

<div>
  <Sidebar location={location} />
  <div class="relative md:ml-64 bg-blueGray-100">
    <AdminNavbar />
    <HeaderStats />
    <div class="px-4 md:px-10 mx-auto w-full -m-24">
      <Router url="admin">
        <Route path="/" component="{Dashboard}" />
        <Route path="users" component="{Users}" />
        <Route path="Class_registration" component="{Class_registration}" />
        <Route path="Course_registration" component="{Course_registration}" />
        <Route path="Fee_management" component="{Fee_management}" />
        <Route path="dashboard" component="{Dashboard}" />
        <Route path="breview" component="{breview}" />
        <Route path="settings" component="{Settings}" />
        <Route path="edit/:email/:number" component="{edit_students}" />
        <Route path="tables" component="{Tables}" />
        <Route path="maps" component="{Maps}" />
        <Route path="calender" component="{calender}" />
        <Route path="Fee_registration" component="{Fee_registration}" />
        <Route path="Leads" component="{Leads}" />
        <Route path="deactiveStudents" component="{deactiveStudents}" />
        <Route path="attendence" component="{attendence}" />
        <Route path="reportattendence" component="{reportattendence}" />
        <Route path="edit_leads/:id" component="{edit_leads}" />
        <Route path="reports" component="{Reports}" />
        <Route path="Lectures" component="{Lectures}" />
        <Route path="MyLectures" component="{MyLectures}" />
        <Route path="Leads_Nurturing" component="{Leads_Nurturing}" />
      </Router>
      <FooterAdmin />
    </div>
  </div>
</div>
