<script>
  // core components
  import CardSettings from "../../components/Cards/Leads_creation.svelte";
  import { onMount } from "svelte";
import { checkPermissions } from "../../lib/checkPermissions";
let hasAccess = false;
onMount(async () => {
  hasAccess = await checkPermissions('tables', '/admin/maps');
});
  export let location;
</script>
{#if hasAccess}
<div class="flex flex-wrap justify-content: center">
  <div class="w-full lg:w-8/12 px-4">
    <CardSettings />
  </div>
</div>
{/if}
