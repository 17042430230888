<script>
  import { onMount } from "svelte";
  
  import CardLineChart from "../../components/Cards/CardLineChart.svelte";
  import Cash_chart from "../../components/Cards/cash_chart.svelte";
  import CardStats from "../../components/Cards/CardStats.svelte";
  import CardBarChart from "../../components/Cards/CardBarChart.svelte";
  import CardPageVisits from "../../components/Cards/CardPageVisits.svelte";
  import CardSocialTraffic from "../../components/Cards/CardSocialTraffic.svelte";
  import { getGeneralDate, getData } from "../../store/db";
  import { checkPermissions } from "../../lib/checkPermissions";
  let hasAccess = false;

  export let location;
  let total_students = [];
  let students = [];
  let general_date = [0, 0, 0];
  let classLevels = {};
  let graduatedCount = 0;
  let Coursesstudents = {};
  let monthly_students = [];
  let lapels = [];
  let classOptions = [];
  let courseOptions = [];
  let selectedClass = "";
  let selectedCourse = "";
  let startDate = "2024-01";
  let endDate = "2024-12";

  async function fetchData() {
    try {
      const generalDate = await getGeneralDate();
      general_date = generalDate;
      const data = await getData();
      total_students = data;
      updateFilterOptions();
      updateStudents();
      updateClassLevels();
      updateCoursesStudents();
      updateMonthlyStudents();
      calculateGraduatedCount()
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }

  function updateMonthlyStudents() {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const monthsDifference = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth()) + 1;

    monthly_students = new Array(monthsDifference).fill(0);
    lapels = [];

    total_students.forEach(student => {
      if (matchesFilters(student)) {
        const registeredDate = new Date(student.registeredDate);
        if (registeredDate >= start && registeredDate <= end) {
          const monthIndex = (registeredDate.getFullYear() - start.getFullYear()) * 12 + (registeredDate.getMonth() - start.getMonth());
          monthly_students[monthIndex] += 1;
        }
      }
    });

    for (let i = 0; i < monthsDifference; i++) {
      const currentMonth = new Date(start.getFullYear(), start.getMonth() + i, 1);
      lapels.push(`${currentMonth.toLocaleString('default', { month: 'short' })}/${currentMonth.getFullYear().toString().slice(-2)}`);
    }
    console.log("monthly students and lapels",monthly_students,lapels)
  }

  function updateStudents() {
    const start = new Date(startDate);
    const end = new Date(endDate);

    students = total_students.filter(student => {
      const registeredDate = new Date(student.registeredDate);
      return registeredDate >= start && registeredDate <= end && matchesFilters(student);
    });
  }

  function matchesFilters(student) {
    return (selectedClass === "" || student.classLevel === selectedClass) &&
           (selectedCourse === "" || student.Course === selectedCourse);
  }

  function updateClassLevels() {
    classLevels = {};
    total_students.forEach(student => {
      if (matchesFilters(student)) {
        const classLevel = student.classLevel;
        if (classLevel in classLevels) {
          classLevels[classLevel] += 1;
        } else {
          classLevels[classLevel] = 1;
        }
      }
    });
  }

  function updateCoursesStudents() {
    Coursesstudents = {};
    total_students.forEach(student => {
      if (matchesFilters(student)) {
        const Coursesstudent = student.Course;
        if (Coursesstudent in Coursesstudents) {
          Coursesstudents[Coursesstudent] += 1;
        } else {
          Coursesstudents[Coursesstudent] = 1;
        }
      }
    });
  }

  function updateFilterOptions() {
    classOptions = Array.from(new Set(total_students.map(student => student.classLevel)));
    courseOptions = Array.from(new Set(total_students.map(student => student.Course)));
  }

  function validateDates() {
    if (new Date(startDate) > new Date(endDate)) {
      endDate = startDate;
    }
  }

  $: if (startDate || endDate || selectedClass || selectedCourse) {
    validateDates();
    updateStudents();
    updateClassLevels();
    updateCoursesStudents();
    updateMonthlyStudents();
  }

  onMount(async () => {
    console.log("you looking the wrong website my man dumb us (yuusuf bidaarta)")
    hasAccess = await checkPermissions('dashboard', '/admin/maps');
    fetchData();
  });
  function calculateGraduatedCount() {
    // Count students whose reason is "Graduated"
    graduatedCount = total_students.filter(student => student.reason === "Graduated").length;
  }
</script>

{#if hasAccess}
<div>
  <div class="relative md:pt-12 pb-16 pt-4">
    <div class="px-4 md:px-10 mx-auto w-full">
      <div>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4 opacity-0">
            <CardStats
              statSubtitle="Total Enrollers"
              statTitle="{general_date[0]}"
              statPercentColor="text-emerald-500"
              statDescripiron="Since last month"
              statIconName="fas fa-users"
              statIconColor="bg-emerald-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <CardStats
              statSubtitle="Courses"
              statTitle="{general_date[2]}"
              statArrow="down"
              statPercent="3.48"
              statPercentColor="text-red-500"
              statDescripiron="Since last week"
              statIconName="fas fa-chart-pie"
              statIconColor="bg-emerald-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <CardStats
              statSubtitle="Classes"
              statTitle="{general_date[1]}"
              statArrow="down"
              statPercent="1.10"
              statPercentColor="text-orange-500"
              statDescripiron="Since last week"
              statIconName="far fa-chart-bar"
              statIconColor="bg-emerald-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <CardStats
              statSubtitle="Graduates"
              statTitle="{graduatedCount}"
              statArrow="up"
              statPercent="5"
              statPercentColor="text-emerald-500"
              statDescripiron="Since last month"
              statIconName="fas fa-percent"
              statIconColor="bg-emerald-500"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-wrap">
    <div class="mt-4 p-4">
      <label for="courseSelector">Select a course:</label>
      <select id="courseSelector" bind:value={selectedCourse}>
        <option value="">All Courses</option>
        {#each courseOptions as courseOption}
          <option value={courseOption}>{courseOption}</option>
        {/each}
      </select>
    </div>
    <div class="mt-4 p-4">
      <label for="classSelector">Select a class:</label>
      <select id="classSelector" bind:value={selectedClass}>
        <option value="">All Classes</option>
        {#each classOptions as classOption}
          <option value={classOption}>{classOption}</option>
        {/each}
      </select>
    </div>
    <div class="mt-4 p-4">
      <label for="startDate">Select start date:</label>
      <input type="month" id="startDate" bind:value={startDate}>
    </div>
    <div class="mt-4 p-4">
      <label for="endDate">Select end date:</label>
      <input type="month" id="endDate" bind:value={endDate}>
    </div>
  </div>
  <div class="flex flex-wrap">
    <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
      <Cash_chart data={monthly_students} labels={lapels} />
    </div>
    <div class="w-full xl:w-4/12 px-4">
      <CardBarChart classLevels={Coursesstudents} />
    </div>
  </div>
  <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
  </div>
  <div class="flex flex-wrap mt-4">
    <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
      <CardPageVisits students={students} />
    </div>
    <div class="w-full xl:w-4/12 px-4">
      <CardSocialTraffic classes={classLevels} />
    </div>
  </div>
</div>
{/if}
