<script>
  import { onMount } from "svelte";
  import {exportToPdf} from "./html_to_pdf"
  import { return_students } from "../../locationStore";
  import { navigate } from "svelte-routing";

  export let students = [];
  let name = "";

  onMount(() => {
    students = return_students();
    if (students.length === 0) {
      navigate("/admin");
    } else {
      phone_email();  // Ensure phone_email is called to set the initial value of `name`
    }
  });

  function phone_email() {
    if (students.length > 0 && students[0].phonenumber !== undefined) {
      name = "Phone Number";
    } else {
      name = "Email";
    }
  }
  function exportPdf(){
      exportToPdf(students)
    }
  function exportToCsv() {
    let csvContent = "data:text/csv;charset=utf-8,"
      + "Name,Registered Date," + name + ",Course\n"
      + students.map(student => 
        `${student.name},${student.registeredDate},${student.phonenumber || student.email},${student.Course}`
      ).join('\n');
    let link = document.createElement('a');
    link.href = encodeURI(csvContent);
    link.download = 'report.csv';
    link.click();
  }

  
</script>

<div class="bg-white p-8 mx-auto my-8 border border-gray-300 shadow-lg w-full">
  <h1 class="text-4xl font-bold mb-4 text-center">Student Report Preview</h1>
  <div class="bg-white p-8 max-w-4xl mx-auto my-8 border border-gray-300 shadow-lg w-full">
    <h2 class="text-3xl font-bold mb-6 mt-4 text-center">Student Report</h2>
    <div class="overflow-x-auto">
      <table class="w-full table-auto border-collapse mb-6">
        <thead>
          <tr class="bg-gray-200">
            <th class="border px-4 py-2 text-left">Name</th>
            <th class="border px-4 py-2 text-left">{name}</th>
            <th class="border px-4 py-2 text-left">Registered Date</th>
            <th class="border px-4 py-2 text-left">Course</th>
          </tr>
        </thead>
        <tbody>
          {#each students as student}
            <tr class="even:bg-gray-100">
              <td class="border px-4 py-2 whitespace-nowrap">{student.name}</td>
              <td class="border px-4 py-2 whitespace-nowrap">{student.phonenumber || student.email}</td>
              <td class="border px-4 py-2 whitespace-nowrap">{student.registeredDate}</td>
              <td class="border px-4 py-2 whitespace-nowrap">{student.Course}</td>
            </tr>
          {/each}
        </tbody>
      </table>
    </div>
    <div class="flex justify-center space-x-4">
      <button on:click={exportToCsv} style="background-color: #10b981; color: white; padding: 0.5rem 1rem; border-radius: 0.375rem;">Export CSV</button>
      <button on:click={exportPdf} style="background-color: #f59e0b; color: white; padding: 0.5rem 1rem; border-radius: 0.375rem;">Export PDF</button>
    </div>
  </div>
</div>
