<script>
  import { onMount } from 'svelte';
  import { saveLeads, getData, getCourses } from "../../store/db";
  import Papa from 'papaparse';

  let courses = {};
  let students = [];
  let new_class = {
    name: "",
    Date: "",
    status: "active",
    course: ""
  };

  let file = null;
  let errorMessage = '';
  let duplicateCount = 0;
  let totalDuplicatesRemoved = 0;
  let studentMatchCount = 0;
  let loading = false; // Loading state
  let excludedLeads = []; // Store filtered leads
  let filteredLeadsReadyToSave = []; // Store the leads that will be saved
  let isProcessed = false;

  let emailColumn = '';
  let phoneColumn = '';

  async function fetchData() {
    try {
      const data = await getData();
      students = data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }

  function validateFile(selectedFile) {
    const validFileTypes = ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
    if (selectedFile && validFileTypes.includes(selectedFile.type)) {
      file = selectedFile;
      errorMessage = '';
    } else {
      errorMessage = 'Invalid file type. Please upload a .csv or Excel file.';
      file = null;
    }
  }

  function handleFileInput(event) {
    const selectedFile = event.target.files[0];
    validateFile(selectedFile);
  }

  function findEmailAndPhoneColumns(leads) {
    const firstRow = leads[0];

    // Find the column that looks like an email column
    emailColumn = Object.keys(firstRow).find(key =>
      key.toLowerCase().includes('email')
    );

    // Find the column that looks like a phone number column
    phoneColumn = Object.keys(firstRow).find(key =>
      key.toLowerCase().includes('phone')
    );
  }

  function removeDuplicatesInCSV(leads) {
    const seen = new Set();
    const deduplicatedLeads = leads.filter(lead => {
      const emailPhoneKey = `${lead[emailColumn]}-${lead[phoneColumn]}`;
      if (seen.has(emailPhoneKey)) {
        duplicateCount++;
        excludedLeads.push({ ...lead, reason: 'Duplicate' });
        return false;
      } else {
        seen.add(emailPhoneKey);
        return true;
      }
    });
    return deduplicatedLeads;
  }

  function compareWithExistingStudents(leads) {
    const studentEmailsPhones = new Set(
      students.flatMap(student => [student.email, student.phoneNumber])
    );

    const filteredLeads = leads.filter(lead => {
      const email = lead[emailColumn];
      const phone = lead[phoneColumn];

      const matchingStudent = students.find(
        student => student.email === email || student.phoneNumber === phone
      );

      if (matchingStudent) {
        studentMatchCount++;
        excludedLeads.push({ ...lead, reason: 'Existing Student', registeredDate: matchingStudent.registeredDate });
        return false;
      }
      return true;
    });

    return filteredLeads;
  }

  async function add_new_class() {
    if (!new_class.name || !new_class.course) {
      alert("Please fill in all fields.");
      return;
    }

    if (!file) {
      alert("Please upload a valid .csv or Excel file.");
      return;
    }

    loading = true; 
    isProcessed = false; // Reset processed state

    Papa.parse(file, {
      header: true,
      complete: async function(results) {
        let leads = results.data;

        if (leads.length === 0) {
          alert("No leads found in the file.");
          loading = false;
          return;
        }

        excludedLeads = []; // Reset the excludedLeads array
        findEmailAndPhoneColumns(leads);

        if (!emailColumn || !phoneColumn) {
          alert("Could not find Email or Phone columns in the file.");
          loading = false;
          return;
        }

        leads = removeDuplicatesInCSV(leads);
        leads = compareWithExistingStudents(leads);

        filteredLeadsReadyToSave = leads; // Store the leads ready to save

        isProcessed = true; // Mark leads as processed
        loading = false; // Hide loading overlay
      },
      error: function(error) {
        console.error("Error parsing CSV: ", error);
        loading = false;
      }
    });
  }

  async function saveFilteredLeads() {
    loading = true;
    try {
      const jsonLeads = JSON.stringify(filteredLeadsReadyToSave);
      await saveLeads(new_class.name, new_class.course, jsonLeads, new_class.Date);
      alert("Leads added successfully!");
    } catch (error) {
      console.error("Error saving class: ", error);
      alert("There was an error saving the class.");
    } finally {
      loading = false;
    }
  }

  onMount(() => {
    fetchData();
    getCourses().then(data => {
      courses = data;
      console.log('Document data:', data);
    });
  });
</script>

<!-- Loading Overlay -->
{#if loading}
  <div class=" relative fixed inset-0 bg-gray-700 bg-opacity-75 flex items-center justify-center z-50">
    <div class="text-blueGray-700 text-xl font-bold">Saving the Leads, please wait...</div>
  </div>
{/if}

<!-- HTML for input form -->
<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-blueGray-700 text-xl font-bold">New Lead</h6>
      <button class="bg-red-400 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" on:click={add_new_class}>
        Add it
      </button>
    </div>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form>
      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">Lead Information</h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="className">Lead Name</label>
            <input id="className" type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="Leads" bind:value={new_class.name} />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="courseSelector">Course</label>
            <select id="courseSelector" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" bind:value={new_class.course}>
              {#each Object.keys(courses) as course}
                <option value={course}>{course}</option>
              {/each}
            </select>
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="fileUpload">Lead File</label>
            <input 
              id="fileUpload" 
              type="file" 
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" 
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" 
              on:change={handleFileInput}
            />
            {#if errorMessage}
              <p class="text-red-500 text-xs italic mt-2">{errorMessage}</p>
            {/if}
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="classDate">Lead Date</label>
            <input id="classDate" type="date" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" bind:value={new_class.Date} />
          </div>
        </div>
      </div>
    </form>
  </div>

  <!-- Exclusion information and continuation button -->
  <div class="px-6 py-4 bg-gray-100 text-center">
    {#if isProcessed}
      {#if excludedLeads.length > 0}
        <!-- Table of excluded leads -->
        <div class="relative mt-6 w-full">
          <h6 class="text-blueGray-700 text-xl font-bold">Excluded Leads</h6>
          <table class="min-w-full bg-white">
            <thead>
              <tr>
                <th class="px-4 py-2 border">Name</th>
                <th class="px-4 py-2 border">Email</th>
                <th class="px-4 py-2 border">Phone</th>
                <th class="px-4 py-2 border">Reason</th>
                <th class="px-4 py-2 border">Registered Date</th> <!-- New column -->
              </tr>
            </thead>
            <tbody>
              {#each excludedLeads as lead}
                <tr>
                  <td class="px-4 py-2 border">{lead.Name}</td>
                  <td class="px-4 py-2 border">{lead.Email}</td>
                  <td class="px-4 py-2 border">{lead['Phone (recommended)']}</td>
                  <td class="px-4 py-2 border">{lead.reason}</td>
                  <td class="px-4 py-2 border">{lead.reason === 'Existing Student' ? lead.registeredDate : ''}</td>
                </tr>
              {/each}
            </tbody>
          </table>
        </div>
      {:else}
        <p>All leads are unique and ready to be saved.</p>
      {/if}
      <!-- Show "Continue" button only after leads are processed -->
      <button class="bg-blue-500 text-white px-4 py-2 mt-4 rounded" 
      style="background-color: #1D4ED8; padding: 10px 20px; border-radius: 8px;" 
      on:click={saveFilteredLeads}>
Continue
</button>
    {/if}
  </div>
</div>
