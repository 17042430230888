<script>
  import { onMount } from 'svelte';
  import { saveClass } from "../../store/db";
  import { getCourses, getTeachers, saveLecture } from "../../store/db";
  import { getUserEmail } from "../../store/store";

  let Lectures = 1; 
  let courses = {};
  let new_class = { name: "", start_date: "", end_date: "", status: "active", course: "" };
  let lectureData = [];
  
  // Reactive block to fetch courses
  $: {
    getCourses().then(data => {
      courses = data;
      console.log('Document data:', data);
    });
  }

  // Function to add a lecture
  export async function addLecture() {
    try {
      for (const lecture of lectureData) {
        if (
          !lecture.name ||
          !lecture.day ||
          !lecture.startTime ||
          !lecture.endTime ||
          !lecture.teacher
        ) {
          alert("Please fill all the fields. of the Lecture");
          return;
        }
        lecture.classLevel = new_class.name
        lecture.course = new_class.course
        lecture.registeredBy = email;
        await saveLecture(lecture);
      }
      // Reset lectureData after saving
      lectureData = [];
    } catch (error) {
      console.error("Failed to save lecture:", error);
      alert("Failed to save lecture.");
    }
  }

  async function fetchTeachers() {
    try {
      teachers = await getTeachers();
    } catch (error) {
      console.error("Error fetching teachers: ", error);
    }
  }

  async function saveClassindb() {
    try {
      await saveClass(new_class);
      await addLecture(); // Call addLecture to save lectures after saving the class
      alert("Class and all lectures saved successfully.");
    } catch (error) {
      console.error("Error saving data:", error);
      alert("Error saving class or lectures.");
    }
  }

  let email = '';
  let lecture = {
    name: "",
    day: "",
    startTime: "",
    endTime: "",
    course: "",      
    classLevel: "",  
    teacher: "",
    registeredBy: ""
  };

  let daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  let teachers = [];

  // Add a new lecture to the list
  function addNewLecture() {
    // Create a new lecture object instead of referencing the existing one
    const newLecture = { ...lecture };
    lectureData = [...lectureData, newLecture]; // Spread operator to create a new array
    Lectures += 1; // Increment the lecture count
  }

  onMount(async () => {
    try {
      email = await getUserEmail();
    } catch (error) {
      console.error(error);
    }
    fetchTeachers();
  });
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-blueGray-700 text-xl font-bold">New Class</h6>
      <button class="bg-red-400 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" on:click={saveClassindb}>
        Save Class & Lectures
      </button>
    </div>
  </div>

  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form>
      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">Class Information</h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="className">Class Name</label>
            <input id="className" type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="Elementary C1" bind:value={new_class.name} />
          </div>
        </div>

        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="courseSelector">Course</label>
            <select id="courseSelector" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" bind:value={new_class.course}>
              {#each Object.keys(courses) as course}
                <option value={course}>{course}</option>
              {/each}
            </select>
          </div>
        </div>

        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="startDate">Created at</label>
            <input id="startDate" type="date" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" bind:value={new_class.start_date} />
          </div>
        </div>

        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="endDate">Ending at</label>
            <input id="endDate" type="date" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" bind:value={new_class.end_date} />
          </div>
        </div>

        <!-- Lectures Section -->
        <div class="w-full px-4">
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">Lectures</h6>

          {#each lectureData as lecture, index}
          <div class="relative flex flex-col min-w-0 break-words w-full mb-6">
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">Lecture Information</h6>
                <div class="flex flex-wrap">
                    <div class="w-full lg:w-6/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Lecture Name</label>
                            <input type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="Lecture Name" bind:value={lecture.name} />
                        </div>
                    </div>
        
                    <div class="w-full lg:w-6/12 px-4">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Day of the Week</label>
                        <select bind:value={lecture.day} class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                            <option value="" disabled>-- Choose Day --</option>
                            {#each daysOfWeek as day}
                                <option value={day}>{day}</option>
                            {/each}
                        </select>
                    </div>
        
                    <div class="w-full lg:w-6/12 px-4">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Start Time</label>
                        <input type="time" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" bind:value={lecture.startTime} />
                    </div>
        
                    <div class="w-full lg:w-6/12 px-4">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">End Time</label>
                        <input type="time" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" bind:value={lecture.endTime} />
                    </div>
                    <div class="w-full lg:w-6/12 px-4">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Teacher</label>
                        <select bind:value={lecture.teacher} class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                            <option value="">-- Choose Teacher --</option>
                            {#each teachers as teacher}
                                <option value={teacher.name}>{teacher.name}</option>
                            {/each}
                        </select>
                    </div>
                </div>
            </div>
          </div>
          {/each}

          <button type="button" class="bg-red-400 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" on:click={addNewLecture}>
            <i class="fa-solid fa-plus"></i>
            Add New Lecture
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
