<script>
    import { writable } from "svelte/store";
    import { onMount } from 'svelte';
    import CardStats from "../../components/Cards/CardStats.svelte";
    import { getData, updateStudentData, findStudentByEmail } from "../../store/db";
  
    let students = writable([]);
    let totalDeactive = 0;
    let graduatedDeactive = 0;
    let leftNoIdea = 0;
    let removed = 0;
    
    let reasons = ["Graduated", "Left (No idea)", "Removed", "Other"];
    let changedStudents = [];
  
    async function fetchData() {
      try {
        const data = await getData(); 
        const filteredData = data.filter(student => student.status === "deactive");
        
        filteredData.forEach(student => {
          if (!student.reason) {
            student.reason = "Other";
          }
          
          if (student.reason === "Graduated") graduatedDeactive++;
          else if (student.reason === "Left (No idea)") leftNoIdea++;
          else if (student.reason === "Removed") removed++;
          
          totalDeactive++;
        });
        
        students.set(filteredData); // Store the deactivated students in the reactive store
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
  
    // Function to save changes for a student
    async function saveChanges(student) {
      try {
        const studentDoc = await findStudentByEmail(student.email); // Fetch student by email
        if (studentDoc) {
          await updateStudentData(studentDoc.id, student); // Update student in the database
          alert(`${student.name}'s status updated successfully!`);
        }
      } catch (error) {
        console.error("Error updating student:", error);
      }
    }
  
    // Add the student to changed list if reason is changed
    function handleReasonChange(student) {
      if (!changedStudents.includes(student)) {
        changedStudents.push(student);
      }
    }
  
    // Save all changed students
    async function saveAllChanges() {
      for (let student of changedStudents) {
        await saveChanges(student); // Save each changed student
      }
      changedStudents = []; // Clear the list of changed students
    }
  
    onMount(() => {
      fetchData(); // Fetch data when the component is mounted
    });
  </script>
  <div>
    <div class="relative md:pt-12 pb-16 pt-4">
        <div class="px-4 md:px-10 mx-auto w-full">
          <div>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-6/12 xl:w-3/12 px-4 opacity-0">
                <CardStats
                  statSubtitle="Total Deactive's"
                  statTitle="{totalDeactive}"
                  statPercentColor="text-emerald-500"
                  statDescripiron="Since last month"
                  statIconName="fas fa-users"
                  statIconColor="bg-emerald-500"
                />
              </div>
              <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Graduated"
                  statTitle="{graduatedDeactive}"
                  statArrow="down"
                  statPercent="3.48"
                  statPercentColor="text-red-500"
                  statDescripiron="Since last week"
                  statIconName="fas fa-chart-pie"
                  statIconColor="bg-emerald-500"
                />
              </div>
              <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Left (No idea)"
                  statTitle="{leftNoIdea}"
                  statArrow="down"
                  statPercent="1.10"
                  statPercentColor="text-orange-500"
                  statDescripiron="Since last week"
                  statIconName="far fa-chart-bar"
                  statIconColor="bg-emerald-500"
                />
              </div>
              <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Removed"
                  statTitle="{removed}"
                  statArrow="up"
                  statPercent="5"
                  statPercentColor="text-emerald-500"
                  statDescripiron="Since last month"
                  statIconName="fas fa-percent"
                  statIconColor="bg-emerald-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  <div class="container mx-auto px-4 mb-16">
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
      <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3 class="font-semibold text-lg text-blueGray-700">All Deactive Students</h3>
          </div>
        </div>
      </div>
  
      <div class="block w-full overflow-x-auto">
        <table class="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th class="px-4 align-middle border py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                Student Name
              </th>
              <th class="px-4 align-middle border py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                Course
              </th>
              <th class="px-4 align-middle border py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                Class Level
              </th>
              <th class="px-4 align-middle border py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                Reason for Deactivation
              </th>
            </tr>
          </thead>
          <tbody>
            {#each $students as student}
              <tr>
                <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {student.name}
                </td>
                <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {student.Course}
                </td>
                <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {student.classLevel}
                </td>
                <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <select bind:value={student.reason} on:change={() => handleReasonChange(student)} class="form-select">
                    {#each reasons as reason}
                      <option value={reason}>{reason}</option>
                    {/each}
                  </select>
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
  
      <div class="mt-4 p-4">
        <button on:click={saveAllChanges} class="bg-indigo-500 text-white w-30 h-12 px-2 py-2 rounded form-input">
          Save All Changes
        </button>
      </div>
    </div>
  </div>
</div>