<script>
    // library for creating dropdown menu appear on click
    import { createPopper } from "@popperjs/core";
    import { navigate } from "svelte-routing";
    import { deleteClassByCourseAndName , findStudentByEmail, updateStudentData } from "../../store/db";
    import { onMount } from 'svelte';
  
    // core components
    let test_student = {};
    export let Class = { name: "", start_date: "", end_date: "", status: "", course: "" };
  
    let dropdownPopoverShow = false;
    let btnDropdownRef;
    let popoverDropdownRef;
  
    const toggleDropdown = (event) => {
      event.preventDefault();
      dropdownPopoverShow = !dropdownPopoverShow;
      if (dropdownPopoverShow) {
        createPopper(btnDropdownRef, popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    };
    
    const handleEditStudentClick = (event) => {
      event.preventDefault();
      if (Class.course &&  Class.name) {
        navigate(`/admin/edit/${Class.course}/${ Class.name}`);
      } else {
        alert("Student email or phone number is missing.");
      }
    };
  
    const handledelteClick = (event) => {
      event.preventDefault();
      if (Class.course && Class.name) {
        deleteClassByCourseAndName(Class.course, Class.name);
      } else {
        alert("class name or course is missing.");
      }
    };
  
    if (Class === test_student) {
      alert("Please edit a normal student.");
    }
    onMount(() =>{
        console.log(Class);
        
    })
  </script>
  
  <div>
    <a
      class="text-blueGray-500 py-1 px-3"
      href="#pablo"
      bind:this="{btnDropdownRef}"
      on:click="{toggleDropdown}"
    >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div
      bind:this="{popoverDropdownRef}"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 {dropdownPopoverShow ? 'block' : 'hidden'}"
    >
      <a
        href="#pablo"
        on:click={handleEditStudentClick}
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        Edit Class data
      </a>
      <a
        href="#pablo"
        on:click={handledelteClick}
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        Delete Class
      </a>
    </div>
  </div>
  