<script>
  import { link } from "svelte-routing";
  import { getUserById } from "../../store/db";
  import { printLoggedInUserUID } from "../../store/store";
  import NotificationDropdown from "../../components/Dropdowns/NotificationDropdown.svelte";
  import UserDropdown from "../../components/Dropdowns/UserDropdown.svelte";
  import { onMount } from "svelte";

  let collapseShow = "hidden";
  let showStudents = false;
  let showClasses = false;
  let showFee = false;
  let showMarketing = false;
  let showLectures = false;
  let MyLectures = false;
  let permissions = {};
  let currentPath = '';
  let activeElement = "/admin/dashboard"; // Set default active element to 'dashboard'
  export let location;

  function toggleCollapseShow(classes) {
    collapseShow = classes;
  }

  function toggleStudents() {
    showStudents = !showStudents;
  }
  function toggleMyLectures() {
    MyLectures = !MyLectures;
  }

  function toggleClasses() {
    showClasses = !showClasses;
  }
  function toggleLectures() {
    showLectures = !showLectures;
  }

  function toggleFee() {
    showFee = !showFee;
  }

  function toggleMarketing() {
    showMarketing = !showMarketing;
  }

  async function getUserPermissions() {
    try {
      const uid = await printLoggedInUserUID();
      if (!uid) {
        return null;
      }

      const docData = await getUserById(uid);
      if (!docData) {
        return {
          dashboardAccess: "yes",
          studentAccess: "yes",
          classesAccess: "yes",
          feeAndReports: "yes",
          userRegistrtion : "yes",
          teacher: true,
          worker: true,
          admin: true,
        };
      }

      return docData;
    } catch (error) {
      console.error("Error getting user permissions:", error);
      return null;
    }
  }

  function setActiveElement(path) {
    activeElement = path; // Change active element on click
  }

  onMount(async () => {
    activeElement = location.pathname;
    permissions = await getUserPermissions();
    currentPath = window.location.pathname;
  });
</script>

<nav
  class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6 text-white"
  style="background-color: #23263A;"
>
  <div class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
    <button
      class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-white rounded border border-solid border-transparent"
      type="button"
      on:click={() => toggleCollapseShow('bg-white m-2 py-3 px-6')}
    >
      <i class="fas fa-bars"></i>
    </button>
    <a
      use:link
      class="md:block text-left md:pb-2 text-white mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
      href="/"
    >
      u-learns
    </a>
    <ul class="md:hidden items-center flex flex-wrap list-none">
      <li class="inline-block relative">
        <NotificationDropdown />
      </li>
      <li class="inline-block relative">
        <UserDropdown />
      </li>
    </ul>

    <div
      class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded {collapseShow}"
      style="background-color: #23263A;"
    >
      <form class="mt-6 mb-4 md:hidden">
        <div class="mb-3 pt-0">
          <input
            type="text"
            placeholder="Search"
            class="border-0 px-3 py-2 h-12 border border-solid border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
          />
        </div>
      </form>

      <hr class="my-4 md:min-w-full" />
      <h6 class="md:min-w-full text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
        u-learns admin layout
      </h6>

      <!-- Sidebar Links -->
      <ul class="md:flex-col md:min-w-full flex flex-col list-none">
        <!-- Dashboard Link -->
        {#if permissions.dashboardAccess === "yes"}
        <li class="items-center">
          <a
            use:link
            href="/admin/dashboard"
            class="text-xs uppercase py-3 font-bold block {activeElement === '/admin/dashboard' ? 'bg-emerald-500 text-white rounded-lg' : 'text-white hover:bg-blueGray-500'} px-4"
            on:click={() => setActiveElement('/admin/dashboard')}
          >
            <i class="fas fa-chart-pie mr-2 text-sm"></i>
            Dashboard
          </a>
        </li>
        {/if}

        <!-- Students Links -->
        {#if permissions.studentAccess === "yes"}
        <li class="items-center">
          <button
            class="text-xs uppercase py-3 font-bold block focus:outline-none px-4"
            on:click={() => toggleStudents()}
          >
            <i class="fas fa-user-graduate mr-2 text-sm"></i>
            Students
            <i class="fas fa-chevron-down ml-2"></i>
          </button>
          <ul class="{showStudents ? 'block' : 'hidden'} ml-4">
            <li class="items-center">
              <a
                use:link
                href="/admin/maps"
                class="text-xs uppercase py-3 font-bold block {activeElement === '/admin/maps' ? 'bg-emerald-500 text-white rounded-lg' : 'text-white hover:bg-blueGray-500'} px-4"
                on:click={() => setActiveElement('/admin/maps')}
              >
                <i class="fas fa-users mr-2 text-sm"></i>
                Student Management
              </a>
              <a
              use:link
              href="/admin/deactiveStudents"
              class="text-xs uppercase py-3 font-bold block {activeElement === '/admin/deactiveStudents' ? 'bg-emerald-500 text-white rounded-lg' : 'text-white hover:bg-blueGray-500'} px-4"
              on:click={() => setActiveElement('/admin/deactiveStudents')}
            >
              <i class="fas fa-users mr-2 text-sm"></i>
              Deactive Students
            </a>
            </li>
            <li class="items-center">
              <a
                use:link
                href="/admin/settings"
                class="text-xs uppercase py-3 font-bold block {activeElement === '/admin/settings' ? 'bg-emerald-500 text-white rounded-lg' : 'text-white hover:bg-blueGray-500'} px-4"
                on:click={() => setActiveElement('/admin/settings')}
              >
                <i class="fas fa-user-plus mr-2 text-sm"></i>
                User Registration
              </a>
            </li>
          </ul>
        </li>
        {/if}

        <!-- Classes Links -->
        {#if permissions.classesAccess === "yes"}
        <li class="items-center">
          <button
            class="text-xs uppercase py-3 font-bold block focus:outline-none px-4"
            on:click={() => toggleClasses()}
          >
            <i class="fas fa-chalkboard mr-2 text-sm"></i>
            Classes
            <i class="fas fa-chevron-down ml-2"></i>
          </button>
          <ul class="{showClasses ? 'block' : 'hidden'} ml-4">
            <li class="items-center">
              <a
                use:link
                href="/admin/tables"
                class="text-xs uppercase py-3 font-bold block {activeElement === '/admin/tables' ? 'bg-emerald-500 text-white rounded-lg' : 'text-white hover:bg-blueGray-500'} px-4"
                on:click={() => setActiveElement('/admin/tables')}
              >
                <i class="fas fa-chalkboard-teacher mr-2 text-sm"></i>
                Class Management
              </a>
            </li>
            <li class="items-center">
              <a
                use:link
                href="/admin/Class_registration"
                class="text-xs uppercase py-3 font-bold block {activeElement === '/admin/Class_registration' ? 'bg-emerald-500 text-white rounded-lg' : 'text-white hover:bg-blueGray-500'} px-4"
                on:click={() => setActiveElement('/admin/Class_registration')}
              >
                <i class="fas fa-book mr-2 text-sm"></i>
                Class Registration
              </a>
            </li>
            <li class="items-center">
              <a
                use:link
                href="/admin/Course_registration"
                class="text-xs uppercase py-3 font-bold block {activeElement === '/admin/Course_registration' ? 'bg-emerald-500 text-white rounded-lg' : 'text-white hover:bg-blueGray-500'} px-4"
                on:click={() => setActiveElement('/admin/Course_registration')}
              >
                <i class="fas fa-book-open mr-2 text-sm"></i>
                Course Registration
              </a>
            </li>
          </ul>
        </li>
        {/if}

        <!-- Fee & Reports Links -->
        {#if permissions.feeAndReports === "yes"}
        <li class="items-center">
          <button
            class="text-xs uppercase py-3 font-bold block focus:outline-none px-4"
            on:click={() => toggleFee()}
          >
            <i class="fas fa-money-bill mr-2 text-sm"></i>
            Fee & Reports
            <i class="fas fa-chevron-down ml-2"></i>
          </button>
          <ul class="{showFee ? 'block' : 'hidden'} ml-4">
            <li class="items-center">
              <a
                use:link
                href="/admin/Fee_registration"
                class="text-xs uppercase py-3 font-bold block {activeElement === '/admin/Fee_registration' ? 'bg-emerald-500 text-white rounded-lg' : 'text-white hover:bg-blueGray-500'} px-4"
                on:click={() => setActiveElement('/admin/Fee_registration')}
              >
                <i class="fas fa-receipt mr-2 text-sm"></i>
                Fees
              </a>
            </li>
            <li class="items-center">
              <a
                use:link
                href="/admin/reports"
                class="text-xs uppercase py-3 font-bold block {activeElement === '/admin/reports' ? 'bg-emerald-500 text-white rounded-lg' : 'text-white hover:bg-blueGray-500'} px-4"
                on:click={() => setActiveElement('/admin/reports')}
              >
              <i class="fas fa-folder-open mr-2 text-sm"></i>
                Reports
              </a>
            </li>
          </ul>
        </li>
        {/if}

        {#if permissions.admin}
        <li class="items-center">
          <button
            class="text-xs uppercase py-3 font-bold block focus:outline-none px-4"
            on:click={() => toggleMarketing()}
          >
            <i class="fas fa-bullhorn mr-2 text-sm"></i> <!-- Bullhorn is suitable for Marketing -->
            Marketing
            <i class="fas fa-chevron-down ml-2"></i>
          </button>
          <ul class="{showMarketing ? 'block' : 'hidden'} ml-4">
            <li class="items-center">
              <a
                use:link
                href="/admin/Leads"
                class="text-xs uppercase py-3 font-bold block {activeElement === '/admin/Leads' ? 'bg-emerald-500 text-white rounded-lg' : 'text-white hover:bg-blueGray-500'} px-4"
                on:click={() => setActiveElement('/admin/Leads')}
              >
                <i class="fas fa-user-plus mr-2 text-sm"></i> <!-- User Plus for New Lead -->
                New Lead
              </a>
            </li>
            <li class="items-center">
              <a
                use:link
                href="/admin/Leads_Nurturing"
                class="text-xs uppercase py-3 font-bold block {activeElement === '/admin/Leads_Nurturing' ? 'bg-emerald-500 text-white rounded-lg' : 'text-white hover:bg-blueGray-500'} px-4"
                on:click={() => setActiveElement('/admin/Leads_Nurturing')}
              >
                <i class="fas fa-users-cog mr-2 text-sm"></i> <!-- Users Cog for Lead Management -->
                Lead Management
              </a>
            </li>
          </ul>
        </li>
        <li class="items-center">
          <button
            class="text-xs uppercase py-3 font-bold block focus:outline-none px-4"
            on:click={() => toggleLectures()}
          >
            <i class="fas fa-chalkboard-teacher mr-2 text-sm"></i> <!-- Chalkboard Teacher for Lectures -->
            Lectures
            <i class="fas fa-chevron-down ml-2"></i>
          </button>
          <ul class="{showLectures ? 'block' : 'hidden'} ml-4">
            <li class="items-center">
              <a
                use:link
                href="/admin/calender"
                class="text-xs uppercase py-3 font-bold block {activeElement === '/admin/calender' ? 'bg-emerald-500 text-white rounded-lg' : 'text-white hover:bg-blueGray-500'} px-4"
                on:click={() => setActiveElement('/admin/calender')}
              >
                <i class="fas fa-calendar-alt mr-2 text-sm"></i> <!-- Calendar for New Lecture -->
                New Lecture
              </a>
            </li>
            <li class="items-center">
              <a
                use:link
                href="/admin/Lectures"
                class="text-xs uppercase py-3 font-bold block {activeElement === '/admin/Lectures' ? 'bg-emerald-500 text-white rounded-lg' : 'text-white hover:bg-blueGray-500'} px-4"
                on:click={() => setActiveElement('/admin/Lectures')}
              >
                <i class="fas fa-folder-open mr-2 text-sm"></i> <!-- Folder Open for Lecture Management -->
                Lecture Management
              </a>
            </li>
          </ul>
        </li>
      {/if}
      {#if permissions.teacher}
        <li class="items-center">
          <button
            class="text-xs uppercase py-3 font-bold block focus:outline-none px-4"
            on:click={() => toggleMyLectures()}
          >
            <i class="fas fa-chalkboard mr-2 text-sm"></i> <!-- Chalkboard for My Lectures -->
            My Lectures
            <i class="fas fa-chevron-down ml-2"></i>
          </button>
          <ul class="{MyLectures ? 'block' : 'hidden'} ml-4">
            <li class="items-center">
              <a
                use:link
                href="/admin/MyLectures"
                class="text-xs uppercase py-3 font-bold block {activeElement === '/admin/MyLectures' ? 'bg-emerald-500 text-white rounded-lg' : 'text-white hover:bg-blueGray-500'} px-4"
                on:click={() => setActiveElement('/admin/MyLectures')}
              >
                <i class="fas fa-book-open mr-2 text-sm"></i> <!-- Book Open for My Lectures -->
                My Lectures
              </a>
            </li>
            <li class="items-center">
              <a
                use:link
                href="/admin/attendence"
                class="text-xs uppercase py-3 font-bold block {activeElement === '/admin/attendence' ? 'bg-emerald-500 text-white rounded-lg' : 'text-white hover:bg-blueGray-500'} px-4"
                on:click={() => setActiveElement('/admin/attendence')}
              >
                <i class="fas fa-clipboard-list mr-2 text-sm"></i> <!-- Clipboard List for New Attendance -->
                New Attendance
              </a>
            </li>
            <li class="items-center">
              <a
                use:link
                href="/admin/reportattendence"
                class="text-xs uppercase py-3 font-bold block {activeElement === '/admin/reportattendence' ? 'bg-emerald-500 text-white rounded-lg' : 'text-white hover:bg-blueGray-500'} px-4"
                on:click={() => setActiveElement('/admin/reportattendence')}
              >
                <i class="fas fa-chart-bar mr-2 text-sm"></i> <!-- Chart Bar for Attendance Management -->
                Attendance Manag
              </a>
            </li>
          </ul>
        </li>
      {/if}      
        {#if permissions.userRegistrtion === "yes"}
        <li class="items-center">
          <a
            use:link
            href="/admin/users"
            class="text-xs uppercase py-3 font-bold block {activeElement === '/admin/users' ? 'bg-emerald-500 text-white rounded-lg' : 'text-white hover:bg-blueGray-500'} px-4"
            on:click={() => setActiveElement('/admin/users')}
          >
            <i class="fas fa-users mr-2 text-sm"></i>
            Users
          </a>
        </li>
        {/if}
      </ul>
    </div>
  </div>
</nav>
