<script>
    import { onMount } from "svelte";
    import { getAllAttendanceDocs } from "../../store/db";
  
    let attendanceDocs = [];
  
    onMount(async () => {
      try {
        // Fetch all attendance documents
        attendanceDocs = await getAllAttendanceDocs();
        console.log(attendanceDocs);
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    });
  
    // Calculate percentage for attendance
    function calculatePercentage(part, total) {
      return total === 0 ? 0 : Math.round((part / total) * 100);
    }
  
    // Check if a key looks like a date (DD/MM/YYYY format)
    function isDateKey(key) {
      return /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(key);
    }
  </script>
  <div style="font-family: 'Roboto', Arial, sans-serif; display: flex; justify-content: left; flex-wrap: wrap;" class="relative">
    {#each attendanceDocs as doc}
      <!-- Card for each lecture -->
      <div style="width: 30%; background-color: white; margin-right: 20px; margin-bottom: 20px; border-radius: 10px; padding: 20px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">
        <div style="font-weight: bold; font-size: 1.2rem; margin-bottom: 10px; color: #333;">
          {doc.Lecture}
        </div>
  
        <!-- Iterate over dynamic date keys in each document -->
        {#each Object.keys(doc).filter(isDateKey) as dateKey}
          <div style="margin-bottom: 15px;">
            <!-- Date heading -->
            <div style="font-weight: bold; color: #333; margin-bottom: 10px; font-size: 1rem; display: flex; justify-content: space-between;">
              <span>{dateKey} Attendance</span>
              <span style="font-weight: normal; color: #888;">{doc[dateKey].TotalStudents} Students</span>
            </div>
  
            <!-- Attendance percentages -->
            <div style="font-size: 0.85rem;">
  
              <!-- Total Presents -->
              <div style="font-weight: bold;">
                Presents: {calculatePercentage(doc[dateKey].TotalPresents, doc[dateKey].TotalStudents)}%
              </div>
              <div style="height: 15px; background-color: #e8f5e9; border-radius: 15px; margin-bottom: 10px; position: relative;">
                <div style="width: {calculatePercentage(doc[dateKey].TotalPresents, doc[dateKey].TotalStudents)}%; background-color: #4CAF50; height: 100%; border-radius: 15px;"></div>
              </div>
  
              <!-- Total Absents -->
              <div style="font-weight: bold;">
                Absents: {calculatePercentage(doc[dateKey].TotalAbsents, doc[dateKey].TotalStudents)}%
              </div>
              <div style="height: 15px; background-color: #ffebee; border-radius: 15px; margin-bottom: 10px; position: relative;">
                <div style="width: {calculatePercentage(doc[dateKey].TotalAbsents, doc[dateKey].TotalStudents)}%; background-color: #F44336; height: 100%; border-radius: 15px;"></div>
              </div>
  
              <!-- Total Lates -->
              <div style="font-weight: bold;">
                Lates: {calculatePercentage(doc[dateKey].TotalLates, doc[dateKey].TotalStudents)}%
              </div>
              <div style="height: 15px; background-color: #fff8e1; border-radius: 15px; margin-bottom: 10px; position: relative;">
                <div style="width: {calculatePercentage(doc[dateKey].TotalLates, doc[dateKey].TotalStudents)}%; background-color: #FF9800; height: 100%; border-radius: 15px;"></div>
              </div>
            </div>
          </div>
        {/each}
      </div>
    {/each}
  </div>
  