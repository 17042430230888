<script>
    import { onMount } from "svelte";
    import { fetchLectures,findusername } from "../../store/db";

    export let lectures = [];
    let username = "";
    let lecturesByDay = {};
    let today = new Date().toLocaleString('en-US', { weekday: 'long' });
    let timers = {};

    onMount(async () => {
        try {
            username = await findusername();
            const response = await fetchLectures();
            let filteredLectures = response.filter(lecture => {
                return ((lecture.teacher === username));
            });
            lectures = Array.isArray(filteredLectures) ? filteredLectures : [];
        } catch (error) {
            alert("Failed to load lectures:", error);
            lectures = [];
        }

        lectures.forEach(lecture => {
            if (!lecturesByDay[lecture.day]) {
                lecturesByDay[lecture.day] = [];
            }
            lecturesByDay[lecture.day].push(lecture);
        });

        if (lecturesByDay[today]) {
            const todayLectures = { [today]: lecturesByDay[today] };
            delete lecturesByDay[today];
            lecturesByDay = { ...todayLectures, ...lecturesByDay };
        }

        setInterval(() => {
            updateTimers();
        }, 60); // Update every second
    });

    function convertToClientTime(eatTime) {
    const eatTimezone = 'Africa/Nairobi'; // East Africa Time (EAT)
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Get today's date in the client's time zone
    const today = new Date().toLocaleDateString('en-CA'); // Format as 'YYYY-MM-DD'

    // Combine the date with the lecture time (assuming it's in "HH:mm" format)
    const dateTimeInEAT = new Date(`${today}T${eatTime}:00`); // ISO format
    const clientTime = new Date(dateTimeInEAT.toLocaleString('en-US', { timeZone: clientTimezone }));

    return clientTime;
}


function updateTimers() {
    const now = new Date();

    Object.keys(lecturesByDay).forEach(day => {
        lecturesByDay[day].forEach(lecture => {
            const clientStartTime = convertToClientTime(lecture.startTime);
            const clientEndTime = convertToClientTime(lecture.endTime);

            const remainingMs = clientEndTime - now;
            if (remainingMs > 0) {
                const remainingSeconds = Math.floor(remainingMs / 1000);
                const hours = Math.floor(remainingSeconds / 3600);
                const minutes = Math.floor((remainingSeconds % 3600) / 60);
                const seconds = remainingSeconds % 60;

                timers[lecture.name] = `${hours}:${minutes}:${seconds} left`;
            } else {
                timers[lecture.name] = '0hr 0min 0sec';
            }
        });
    });
}


    function calculateDuration(start, end) {
        const startTime = new Date(`1970-01-01T${start}:00`);
        const endTime = new Date(`1970-01-01T${end}:00`);
        const durationMs = endTime - startTime;
        const durationMin = Math.floor(durationMs / 60000);
        const hours = Math.floor(durationMin / 60);
        const minutes = durationMin % 60;

        return hours > 0 ? `${hours}hr ${minutes}min` : `${minutes}min`;
    }
</script>

<div style="font-family: sans-serif; display: flex; justify-content: left; flex-wrap: wrap; " class="w-full">
    {#each Object.keys(lecturesByDay) as day}
        <div style="width: 30%; background-color: {day === today ? '#fff3cd' : 'white'}; margin-right: 20px; margin-bottom: 20px; border-radius: 10px; position: relative; padding-left: 20px;">
            <div style="font-weight: bold; font-size: 1rem; padding: 10px;">
                {day === today ? 'Today\'s Classes' : `Classes for ${day}`}
            </div>
            <div style="position: absolute; top: 40px; left: 20px; width: 2px; height: calc(100% - 40px); background-color: #d3d3d3; z-index: 0;"></div>
            
            <div style="position: relative; z-index: 1; padding-top: 20px;">
                {#each lecturesByDay[day] as lecture, index}
                    <div style="display: flex; align-items: center; margin-bottom: 30px; position: relative; {day === today ? 'font-style: italic;' : ''}">
                        <!-- Circle Icon Centered on Line -->
                        <span style="margin-right: 10px; position: relative;">
                            <i class="fa-solid fa-circle" style="color: {lecture.course === 'Business' ? '#FFD700' : '#20C997'}; z-index: 2; position: relative; left: -7px;"></i>
                        </span>
                        <!-- Lecture Details -->
                        <div style="flex: 1;">
                            <div style="font-weight: bold; color: #333;">{convertToClientTime(lecture.startTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
                            <div style="color: #888;">{lecture.name} - {lecture.classLevel}</div>
                            <div style="color: #333;">by You</div>
                        </div>

                        <!-- Timer (Bold and Large) for today's classes -->
                        {#if day === today}
                            <div style="font-size: 1rem; color: #ff6f61; font-weight: bold; padding-right: 20px;">
                                <i class="fa-solid fa-clock"></i> {timers[lecture.name]}
                            </div>
                        {/if}

                        <!-- Duration for non-today lectures -->
                        {#if day !== today}
                            <div style="font-size: 0.85rem; color: #888; margin-left: auto; padding-right: 20px;">
                                {calculateDuration(lecture.startTime, lecture.endTime)}
                            </div>
                        {/if}
                    </div>
                {/each}
            </div>
        </div>
    {/each}
</div>
