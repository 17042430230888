import html2pdf from 'html2pdf.js';

export function exportToPdf(students) {
  let htmlContent = `
    <html>
    <head>
      <title>Student Report</title>
      <style>
        body {
          font-family: Arial, sans-serif;
          margin: 0;
          padding: 20px;
          background-color: #f8f9fa;
        }
        h1 {
          text-align: center;
          color: #343a40;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 20px;
        }
        th, td {
          border: 1px solid #dee2e6;
          padding: 12px;
          text-align: left;
        }
        th {
          background-color: #e9ecef;
        }
        tr:nth-child(even) {
          background-color: #f8f9fa;
        }
        tr:hover {
          background-color: #e2e6ea;
        }
        @media print {
          body {
            margin: 0;
          }
          table {
            width: 100%;
          }
          th, td {
            border: 1px solid #000;
          }
          th {
            background-color: #ddd;
          }
        }
      </style>
    </head>
    <body>
      <h1>Student Report</h1>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Registered Date</th>
            <th>Contact Info</th>
            <th>Course</th>
          </tr>
        </thead>
        <tbody>
          ${students.map(student => `
            <tr>
              <td>${student.name}</td>
              <td>${student.registeredDate}</td>
              <td>${student.phonenumber || student.email}</td>
              <td>${student.Course}</td>
            </tr>
          `).join('')}
        </tbody>
      </table>
    </body>
    </html>
  `;

  let container = document.createElement('div');
  container.innerHTML = htmlContent;
  document.body.appendChild(container);

  html2pdf()
    .from(container)
    .save('student_report.pdf')
    .then(() => {
      document.body.removeChild(container);
    });
}
