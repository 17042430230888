import { navigate } from "svelte-routing";
let students = []
export function DownloadUserData(dataArray) {
    students = []
    students = dataArray
    console.log(students)
    navigate("/admin/breview/")

}

export function return_students(){
    return students
}