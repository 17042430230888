<script>
  import ClassDropdown from "../../components/Dropdowns/ClassDropdown.svelte";
  import TableDropdown from "../../components/Dropdowns/TableDropdown.svelte";

  // can be one of light or dark
  export let color = "light";
  export let class_date = { 
      name: "Elementary C1", 
      start_date: "12/24/2024", 
      end_date:"12/24/2024", 
      status: "active",
      course: "English"
  };

  // Define an array of student objects
  export let students = [
      { name: "cqani mohamed", fee: "$27.5 USD", status: "active", registeredBy: "aasiyo", websiteCompletion: "60%", course: "english", class: "elementary c1" },
      { name: "faarax warsame cabdile", fee: "$55 USD", status: "active", registeredBy: "cqani", websiteCompletion: "100%", course: "english", class: "intermediate c2" },
      { name: "cabdullaahi warsame cintele", fee: "$22.5 USD", status: "unactive", registeredBy: "cqani", websiteCompletion: "73%", course: "english", class: "advance c3" },
      { name: "faarax warsame washax", fee: "$27.5 USD", status: "active", registeredBy: "aasiyo", websiteCompletion: "90%", course: "english", class: "elementary c1" },
      { name: "ayaan maxamed shire", fee: "$27.5 USD", status: "active", registeredBy: "cqani", websiteCompletion: "100%", course: "english", class: "intermediate c2" },
      { name: "John Doe", fee: "$100 USD", status: "active", registeredBy: "Jane Smith", websiteCompletion: "80%", course: "acca", class: "acca 1" },
      { name: "Jane Smith", fee: "$120 USD", status: "active", registeredBy: "John Doe", websiteCompletion: "95%", course: "acca", class: "acca 2" }
  ];

  // Helper function to calculate the percentage of time completed
  function calculateCompletionPercentage(startDate, endDate) {
      const start = new Date(startDate);   // Convert start_date to Date object
      const end = new Date(endDate);       // Convert end_date to Date object
      const today = new Date();            // Get current date

      if (today < start) return 0;         // If today's date is before the start date, return 0%
      if (today > end) return 100;         // If today's date is after the end date, return 100%

      const totalTime = end - start;       // Total duration of the class in milliseconds
      const timePassed = today - start;    // Time passed from the start date in milliseconds

      return Math.floor((timePassed / totalTime) * 100);  // Calculate percentage of time completed
  }

  // Calculate the completion percentage for the current class
  let completionPercentage = calculateCompletionPercentage(class_date.start_date, class_date.end_date);

</script>

{#if students.length > 0}
<div
class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}"
>

<div class="rounded-t mb-0 px-4 py-3 border-0">
  <div class="flex flex-wrap items-center">
    <div class="relative w-full px-4 max-w-full flex-grow flex-1">
      
    </div>
  </div>
</div>
<div class="block w-full overflow-x-auto">
  <!-- Projects table -->
  <table class="items-center w-full bg-transparent border-collapse">
    <thead>
        <tr>
          <th
            class="px-6 align-middle  py-3 text-xs uppercase whitespace-nowrap font-bold text-left"
          >
            Class Name
          </th>
          <th
            class="px-6 align-middle  py-3 text-xs uppercase whitespace-nowrap font-bold text-left "
          >
          Class Status
          </th>
          <th
            class="px-6 align-middle py-3 text-xs uppercase whitespace-nowrap font-bold text-left  "
          >
          Start Date
          </th>
          <th
            class="px-6 align-middle py-3 text-xs uppercase whitespace-nowrap font-bold text-left "
          >
          End Date
          </th>
          <th
            class="px-6 align-middle py-3 text-xs uppercase whitespace-nowrap font-bold text-left "
          >
          Completed so far
          </th>
          <th
            class="px-6 align-middle py-3 text-xs uppercase  whitespace-nowrap font-semibold text-left"
          ></th>
        </tr>
      <tr>
        <th
          class="px-6 align-middle  py-3 text-xs uppercase whitespace-nowrap font-semibold text-left "
        >
          {class_date.name}
        </th>
        <th
          class="px-6 align-middle  py-3 text-xs uppercase whitespace-nowrap font-semibold text-left "
        ><i class="fas fa-circle  mr-2 {class_date.status === 'active' ? 'text-emerald-500':'text-red-500 '}"></i>
        {class_date.status}
        </th>
        <th
          class="px-6 align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left "
        >
        {class_date.start_date}
        </th>
        <th
          class="px-6 align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left "
        >
        {class_date.end_date}
        </th>
        <th>
          <div class="flex items-center">
            <span class="mr-2">{completionPercentage}%</span>
            <div class="relative w-full">
              <div class="overflow-hidden h-2 text-xs flex rounded bg-red-200">
                <div
                  style="width: {completionPercentage}%;"
                  class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"
                ></div>
              </div>
            </div>
          </div>
        </th>
        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
          <ClassDropdown Class={class_date} />
        </th>
        <th
          class="px-6 align-middle py-3 text-xs uppercase  whitespace-nowrap font-semibold text-left"
        ></th>
      </tr>
      <tr>
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
        >
          Student Name
        </th>
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
        >
          Fee
        </th>
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
        >
          Status
        </th>
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
        >
          Register By
        </th>
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
        >
         course
        </th>
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
        ></th>
      </tr>
    </thead>
    <tbody>
      {#each students as student}
      <tr>
        <th
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center"
        >
          <span
            class="ml-3 font-bold {color === 'light' ? 'text-blueGray-600' : 'text-white'}"
          >
            {student.name}
          </span>
        </th>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
          {student.fee}
        </td>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
          <i class="fas fa-circle  mr-2 {student.status === 'active' ? 'text-emerald-500':'text-red-500 '}"></i> {student.status}
        </td>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
          {student.registeredBy}
        </td>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
          {student.course}
        </td>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
        >
          <TableDropdown />
        </td>
      </tr>
      {/each}
    </tbody>
  </table>
</div>
</div>
{/if}
