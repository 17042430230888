<script>
  import { onMount, onDestroy } from "svelte";
  import Chart from "chart.js";

  // Define classLevels
  export let classLevels = {};

  // Initialize chart
  let chart;

  onMount(() => {
    chart_update();
  });

  function chart_update() {
    const ctx = document.getElementById("bar-chart").getContext("2d");
    chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: Object.keys(classLevels),
        datasets: [{
          label: "Total Students",
          backgroundColor: "#ed64a6",
          borderColor: "#ed64a6",
          data: Object.values(classLevels),
          fill: false,
          barThickness: 8
        }]
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: "Orders Chart",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        legend: {
          labels: {
            fontColor: "rgba(0,0,0,.4)",
          },
          align: "end",
          position: "bottom",
        },
        scales: {
          xAxes: [{
            display: false,
            scaleLabel: {
              display: true,
              labelString: "Month",
            },
            gridLines: {
              borderDash: [2],
              borderDashOffset: [2],
              color: "rgba(33, 37, 41, 0.3)",
              zeroLineColor: "rgba(33, 37, 41, 0.3)",
              zeroLineBorderDash: [2],
              zeroLineBorderDashOffset: [2],
            },
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: false,
              labelString: "Value",
            },
            gridLines: {
              borderDash: [2],
              drawBorder: false,
              borderDashOffset: [2],
              color: "rgba(33, 37, 41, 0.2)",
              zeroLineColor: "rgba(33, 37, 41, 0.15)",
              zeroLineBorderDash: [2],
              zeroLineBorderDashOffset: [2],
            },
          }],
        },
      },
    });
  }

  // Watch for changes in classLevels
  let oldClassLevels = {};
  $: {
    if (Object.keys(classLevels).length > Object.keys(oldClassLevels).length) {
      console.log("the classLevels variable changed", classLevels);
      chart_update();
    }
    oldClassLevels = { ...classLevels };
  }

  onDestroy(() => {
    // Clean up chart instance when component is destroyed
    chart.destroy();
  });

</script>

<div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
  <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
    <div class="flex flex-wrap items-center">
      <div class="relative w-full max-w-full flex-grow flex-1">
        <h6 class="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
          Courses
        </h6>
        <h2 class="text-blueGray-700 text-xl font-semibold">
          Total Classes
        </h2>
      </div>
    </div>
  </div>
  <div class="p-4 flex-auto">
    <div class="relative h-350-px">
      <canvas id="bar-chart"></canvas>
    </div>
  </div>
</div>
