<script>
  import CardLineChart from "../../components/Cards/cash_chart.svelte";
  import CardBarChart from "../../components/Cards/money_bichart.svelte";
  import CardPageVisits from "../../components/Cards/unbaid_students.svelte";
  import CardSocialTraffic from "../../components/Cards/CardSocialTraffic.svelte";
  import { getData, getFirstCourseData, getGeneralDate,updateFeeStatus } from "../../store/db";
  import { onMount } from 'svelte';
  import { writable } from "svelte/store";
  import { checkPermissions } from "../../lib/checkPermissions";
  import { DownloadUserData } from "../../locationStore";

  let hasAccess = false;
  let students = [];
  let courses = {};
  let classes = [];
  let classStudents = {};
  let selectedCourse = 'all';
  let selectedClass = 'all';
  let paymentStatus = 'all';
  let StudentStatus = 'all';
  let startDate = '';
  let endDate = '';
  let filteredStudents = [];
  let showContent = false;
  let unupdated_student = [];

  async function fetchData() {
    try {
      getGeneralDate();
      const class_data = await getFirstCourseData();
      courses = class_data;
      const data = await getData();
      students = data.map(student => ({
        name: student.name,
        registeredDate: student.registeredDate,
        paidFee: student.paidFee,
        fee_status: student.fee_status,
        status: student.status,
        registeredBy: student.registeredBy,
        classLevel: student.classLevel,
        totalFee: student.totalFee,
        lastPaidTime: student.lastPaidTime,
        lastPaidAmount: student.lastPaidAmount,
        phonenumber: student.phoneNumber,
        Course: student.Course
      }));
      unupdated_student = students;
      updateClasses();
      updateStudents();
      filterStudents();
      console.log('Fetched students: ', students);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }

  onMount(async () => {
    hasAccess = await checkPermissions('feeRegistration', '/admin/maps');
    if (hasAccess) {
      await fetchData();
    }
  });

  function updateClasses() {
    if (selectedCourse === 'all') {
      classes = Object.values(courses).reduce((acc, cur) => acc.concat(cur.map(c => c.name)), []);
    } else {
      classes = courses[selectedCourse]?.map(classObj => classObj.name) || [];
    }
  }

  function updateStudents() {
    classStudents = {};
    students.forEach(student => {
      if (!classStudents[student.classLevel]) {
        classStudents[student.classLevel] = [];
      }
      classStudents[student.classLevel].push(student);
    });
  }

  function filterStudentsByPaymentStatus(students, status, active_status) {
    if (status === 'all' && active_status === 'all') {
      return students;
    } else if (status !== 'all' && active_status === 'all') {
      return students.filter(student => student.fee_status === status);
    } else if (status === 'all' && active_status !== 'all') {
      return students.filter(student => student.status === active_status);
    } else {
      return students.filter(student => student.status === active_status && student.fee_status === status);
    }
  }

  function filterStudents() {
    filteredStudents = unupdated_student.filter(student => {
      return (selectedCourse === "all" || student.Course === selectedCourse)
        && (selectedClass === "all" || student.classLevel === selectedClass)
        && (StudentStatus === "all" || student.status === StudentStatus)
        && (paymentStatus === "all" || student.fee_status === paymentStatus)
        && (!startDate || new Date(student.registeredDate) >= new Date(startDate))
        && (!endDate || new Date(student.registeredDate) <= new Date(endDate));
    });
  }

  function export_fun() {
    if (filteredStudents.length === 0) {
      alert("No students match the selected criteria.");
    } else {
      DownloadUserData(filteredStudents);
    }
  }

  function view_fun() {
    showContent = true;
  }

  $: {
    filterStudents();
    console.log('Filtered students:', filteredStudents);
  }
</script>

{#if hasAccess}
<div class="mt-4">
  <div class="flex flex-wrap p-4 mt-10">hello</div>
  <div class="flex flex-wrap p-4 mt-10">
    <div class="mt-4 p-4">
      <label for="courseSelector">Select a course:</label>
      <select id="courseSelector" bind:value={selectedCourse} on:change={updateClasses}>
        <option value="all">All courses</option>
        {#each Object.keys(courses) as course}
          <option value={course}>{course}</option>
        {/each}
      </select>
    </div>

    {#if selectedCourse !== 'all'}
      <div class="mt-4 p-4">
        <label for="classSelector">Select a class:</label>
        <select id="classSelector" bind:value={selectedClass} on:change={filterStudents}>
          <option value="all">All Classes</option>
          {#each classes as className}
            <option value={className}>{className}</option>
          {/each}
        </select>
      </div>
    {/if}

    <div class="mt-4 p-4">
      <label for="paymentStatusSelector">Select payment status:</label>
      <select id="paymentStatusSelector" bind:value={paymentStatus} on:change={filterStudents}>
        <option value="all">All</option>
        <option value="paid">Paid</option>
        <option value="unpaid">Unpaid</option>
      </select>
    </div>
    <div class="mt-4 p-4">
      <label for="StudentStatusSelector">Select Student status:</label>
      <select id="StudentStatusSelector" bind:value={StudentStatus} on:change={filterStudents}>
        <option value="all">All</option>
        <option value="active">Active</option>
        <option value="deactive">Deactive</option>
      </select>
    </div>

    <div class="mt-4 p-4">
      <label for="startDate">Start Date:</label>
      <input type="date" id="startDate" bind:value={startDate} class="form-input" on:change={filterStudents}>
    </div>
    <div class="mt-4 p-4">
      <label for="endDate">End Date:</label>
      <input type="date" id="endDate" bind:value={endDate} class="form-input" on:change={filterStudents}>
    </div>

    <div class="mt-4 p-4">
      <button on:click={export_fun} class="bg-indigo-500 text-white w-30 h-12 px-2 py-2 rounded form-input" type="button">
        EXPORT
      </button>
      <button 
        on:click={view_fun} 
        style="
          background-color: #10B981; /* bg-green-500 */
          color: white; /* text-white */
          width: 7.5rem; /* w-30 */
          height: 3rem; /* h-12 */
          padding: 0.5rem; /* px-2 py-2 */
          border-radius: 0.375rem; /* rounded */
          margin-left: 0.5rem; /* ml-2 */
        " 
        type="button">
        VIEW
      </button>
    </div>
  </div>

  {#if showContent}
    {#if selectedCourse === 'all'}
      {#each Object.keys(courses) as course}
        {#each courses[course] as classObj}
          {#if classStudents[classObj.name]?.length > 0}
            <div class="flex flex-wrap mt-4">
              <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
                <CardPageVisits Title={classObj.name} students={filterStudentsByPaymentStatus(classStudents[classObj.name], paymentStatus, StudentStatus)}/>
              </div>
            </div>
          {/if}
        {/each}
      {/each}
    {:else}
      {#each classes as className}
        {#if selectedClass === 'all' || selectedClass === className}
          {#if classStudents[className]?.length > 0}
            <div class="flex flex-wrap mt-4">
              <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
                <CardPageVisits Title={className} students={filterStudentsByPaymentStatus(classStudents[className], paymentStatus, StudentStatus)}/>
              </div>
            </div>
          {/if}
        {/if}
      {/each}
    {/if}
  {/if}
</div>
{/if}
