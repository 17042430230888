<script>
    // core components
    import CardSettings from "../../components/Cards/NewClaneder.svelte";
    import CardProfile from "../../components/Cards/CardProfile.svelte";
    export let location;
  </script>
  
  <div class="flex flex-wrap justify-content: center">
    <div class="w-full lg:w-8/12 px-4">
      <CardSettings />
    </div>
    
  </div>
  