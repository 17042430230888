<script>
  import { onMount, afterUpdate } from "svelte";
  import Chart from "chart.js";

  export let data = [];
  export let labels = [];

  let chart;

  onMount(() => {
    createChart();
  });

  afterUpdate(() => {
    if (chart) {
      updateChart();
    }
  });

  function createChart() {
    const config = {
      type: "line",
      data: {
        labels: labels,
        datasets: [
          {
            label: "students",
            backgroundColor: "rgba(237, 100, 166, 0.2)",
            borderColor: "#ed64a6",
            data: data,
            borderWidth: 2,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: "Enrolled students",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Month",
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Money",
              },
              gridLines: {
                display: true,
              },
            },
          ],
        },
      },
    };

    let ctx = document.getElementById("line-chart").getContext("2d");
    chart = new Chart(ctx, config);
  }

  function updateChart() {
    chart.data.labels = labels;
    chart.data.datasets[0].data = data;
    chart.update();
  }
</script>

<div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
  <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
    <div class="flex flex-wrap items-center">
      <div class="relative w-full max-w-full flex-grow flex-1">
        <h6 class="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
          Months
        </h6>
        <h2 class="text-blueGray-700 text-xl font-semibold">
          Enrolled students
        </h2>
      </div>
    </div>
  </div>
  <div class="p-4 flex-auto">
    <div class="relative h-350-px">
      <canvas id="line-chart"></canvas>
    </div>
  </div>
</div>
