// src/utils/checkPermissions.js
import { getUserById } from "../store/db";
import { printLoggedInUserUID } from "../store/store";
import { navigate } from "svelte-routing";

export async function checkPermissions(requiredAccess, redirectPath) {
  try {
    const uid = await printLoggedInUserUID();
    if (!uid) {
      navigate("/");
      return false;
    }

    const docData = await getUserById(uid);
    if (!docData) {
      // Allow access if docData cannot be retrieved
      return true;
    }

    const { classesAccess, dashboardAccess, feeAndReports, studentAccess } = docData;

    // Define permission checks based on your requirements
    const permissions = {
      dashboard: dashboardAccess,
      maps: studentAccess,
      tables: classesAccess,
      feeRegistration: feeAndReports,
    };

    if (permissions[requiredAccess] === "no") {
      navigate(redirectPath);
      return false;
    }

    return true;
  } catch (error) {
    console.error("Error checking permissions:", error);
    navigate("/");
    return false;
  }
}
