<script>
  import { onMount } from 'svelte';
  import { getFileURLById, uploadFileToLeadsFolder, getFilenamebyId } from "../../store/db";
  import Leads_to_student from "./Leads_to_student.svelte";
  import Papa from 'papaparse'; // Import Papa Parse for CSV export

  let fileURL = '';
  let jsonData = [];
  let filteredData = [];
  let searchTerm = '';
  let leadFilter = ''; // New variable for lead status filter
  let errorMessage = '';
  let isLoading = false;
  const initialUsersToShow = 500;
  let usersToShow = initialUsersToShow;
  let hasChanges = false;
  let updatedEnrolledUsers = [];

  function getIdFromURL() {
    const path = window.location.pathname;
    const parts = path.split('/');
    return parts[parts.length - 1];
  }

  async function fetchAndParseJSON(id) {
    isLoading = true;
    errorMessage = '';
    try {
      fileURL = await getFileURLById(id);

      if (fileURL) {
        const response = await fetch(fileURL);
        const data = await response.json();
        jsonData = ensureLeadsColumn(data);
        filteredData = jsonData.slice(0, usersToShow);
      } else {
        errorMessage = 'No valid URL found for the provided ID';
      }
    } catch (error) {
      console.error("Error fetching or parsing JSON:", error);
      errorMessage = 'Error fetching or parsing the JSON file';
    } finally {
      isLoading = false; // Stop loading after the data is loaded
    }
  }

  function ensureLeadsColumn(data) {
    return data.map(user => {
      if (!('Leads' in user) || user.Leads === '') {
        user.Leads = 'Interest';
      }
      return user;
    });
  }

  function extractRelevantFields(data) {
    return data.map(user => {
      let name = '';
      let number = '';
      let email = '';
      let leads = user.Leads || 'Interest';

      for (let key in user) {
        const value = user[key];

        if (/name/i.test(key)) name = value;
        if (/phone|number/i.test(key) && /^[\d\s+()-]+$/.test(value)) number = value;
        if (/email/i.test(key)) email = value;
      }

      return { name, number, email, leads };
    });
  }

  function filterData() {
    let filtered = jsonData;
    if (searchTerm.trim() !== '') {
      filtered = filtered.filter(user => {
        const { name, number, email } = extractRelevantFields([user])[0];
        const search = searchTerm.toLowerCase();
        return (
          name.toLowerCase().includes(search) ||
          number.toLowerCase().includes(search) ||
          email.toLowerCase().includes(search)
        );
      });
    }

    if (leadFilter) {
      filtered = filtered.filter(user => user.Leads === leadFilter);
    }

    filteredData = filtered.slice(0, usersToShow);
  }

  function handleInputChange(userIndex, key, value) {
    filteredData[userIndex][key] = value;
    const userInOriginalData = jsonData.find(user => user.email === filteredData[userIndex].email);
    if (userInOriginalData) {
      userInOriginalData[key] = value;
    }
    if (key === 'Leads' && value === 'Enrollment') {
      const relevantUser = extractRelevantFields([filteredData[userIndex]])[0];
      if (!updatedEnrolledUsers.some(user => user.email === relevantUser.email)) {
        updatedEnrolledUsers.push(relevantUser);
      }
    }
    hasChanges = true;
  }

  async function saveChanges() {
    try {
      const jsonString = JSON.stringify(jsonData, null, 2);
      const fileName = await extractFileName(); 
      const file = new File([jsonString], fileName, { type: 'application/json' });
      await uploadFileToLeadsFolder(file);

      console.log("Changes saved and file uploaded successfully.");
      hasChanges = false;
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  }

  async function extractFileName() {
    let fileName = await getFilenamebyId(getIdFromURL());
    return fileName;
  }

  function cancelChanges() {
    fetchAndParseJSON(getIdFromURL());
    hasChanges = false;
  }

  function loadMoreUsers() {
    usersToShow += 500;
    filterData();
  }

  // Function to download filtered data as CSV (all filtered users, not just displayed ones)
  function downloadCSV() {
    let allFilteredData = jsonData;

    if (searchTerm.trim() !== '' || leadFilter !== '') {
      allFilteredData = jsonData.filter(user => {
        const { name, number, email } = extractRelevantFields([user])[0];
        const search = searchTerm.toLowerCase();

        const matchesSearch = searchTerm.trim() === '' || (
          name.toLowerCase().includes(search) ||
          number.toLowerCase().includes(search) ||
          email.toLowerCase().includes(search)
        );

        const matchesLeadFilter = leadFilter === '' || user.Leads === leadFilter;

        return matchesSearch && matchesLeadFilter;
      });
    }

    const csvData = extractRelevantFields(allFilteredData);
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "filtered_students.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  onMount(() => {
    const id = getIdFromURL();
    fetchAndParseJSON(id);
  });
</script>

<div class="container mx-auto px-4 mb-16">
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      {#if isLoading}
        <div style="display: flex; justify-content: center; align-items: center; height: 100vh; font-size: 1.5rem; color: #555;">
          <div style="animation: pulse 1.5s infinite; margin-right: 10px;">⏳</div>
          <div>Loading the Leads data...</div>
        </div>
      {/if}
      {#if !isLoading}
      {#if errorMessage}
        <p style="color: red;">{errorMessage}</p>
      {/if}

      <input
        type="text"
        placeholder="Search one person... start typing to search"
        value={searchTerm}
        on:input={(e) => {
          searchTerm = e.target.value;
          filterData();
        }}
        style="width: 100%; padding: 10px; margin-bottom: 20px; border-radius: 5px; border: 1px solid #ddd;"
      />

      <!-- Dropdown filter for leads status -->
      <select on:change={(e) => { leadFilter = e.target.value; filterData(); }} style="margin-bottom: 20px;">
        <option value="">All Leads</option>
        <option value="Interest">Interest</option>
        <option value="Engagement">Engagement</option>
        <option value="Enrollment">Enrollment</option>
      </select>

      {#if filteredData.length > 0}
        <table style="width: 100%;">
          <thead>
            <tr>
              <th style="padding: 10px; text-align: center;">Name</th>
              <th style="padding: 10px; text-align: center;">Phone</th>
              <th style="padding: 10px; text-align: center;">Email</th>
              <th style="padding: 10px; text-align: center;">Leads</th>
            </tr>
          </thead>
          <tbody>
            {#each extractRelevantFields(filteredData) as user, index}
              <tr>
                <td style="padding: 10px;">
                  <input type="text" value={user.name} on:input={(e) => handleInputChange(index, 'name', e.target.value)} 
                    style="width: 100%; padding: 8px; border-radius: 5px; border: 1px solid #ccc;"/>
                </td>
                <td style="padding: 10px;">
                  <input type="text" value={user.number} on:input={(e) => handleInputChange(index, 'number', e.target.value)} 
                    style="width: 100%; padding: 8px; border-radius: 5px; border: 1px solid #ccc;"/>
                </td>
                <td style="padding: 10px;">
                  <input type="text" value={user.email} on:input={(e) => handleInputChange(index, 'email', e.target.value)} 
                    style="width: 100%; padding: 8px; border-radius: 5px; border: 1px solid #ccc;"/>
                </td>
                <td style="padding: 10px;">
                  <select on:change={(e) => handleInputChange(index, 'Leads', e.target.value)} value={user.leads}
                    style="width: 100%; padding: 8px; border-radius: 5px; border: 1px solid #ccc;">
                    <option value="Interest">Interest</option>
                    <option value="Engagement">Engagement</option>
                    <option value="Enrollment">Enrollment</option>
                  </select>
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      {/if}

      {#if hasChanges}
        <div style="position: fixed; bottom: 20px; right: 20px;">
          <button on:click={saveChanges} style="margin-right: 10px; padding: 8px; background-color: #5a67d8; color: white; border-radius: 4px; cursor: pointer;">
            Save
          </button>
          <button on:click={cancelChanges} style="padding: 8px; background-color: #e53e3e; color: white; border-radius: 4px; cursor: pointer;">
            Cancel
          </button>
        </div>
      {/if}

      <!-- Buttons container (stacked vertically) -->
      <div style="text-align: center; margin: 20px;">
        <button on:click={loadMoreUsers} style="padding: 8px; background-color: #4CAF50; color: white; border-radius: 4px; cursor: pointer; margin-bottom: 10px;">
          Load More Users
        </button>

        <button on:click={downloadCSV} style="padding: 8px; background-color: #2196F3; color: white; border-radius: 4px; cursor: pointer;">
          Download Filtered Students
        </button>
      </div>
      {/if}
    </div>
  </div>
</div>
