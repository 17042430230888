import { db,storage } from "../lib/firebase/firebase"
import { collection, addDoc ,getDocs,updateDoc, doc ,setDoc,getDoc ,query, where, deleteDoc} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL,listAll } from 'firebase/storage';

import {printLoggedInUserUID} from "./store"
import {getUserEmail } from "./store"
import { navigate } from "svelte-routing";

let Student ={ name: "Maryan Ahmed",status:"active" , phoneNumber:"+2520907545054", email:"cqani@gmail.com", registeredDate: "12/4/2024", lastPaidTime: "", paidFee: 0.0, totalFee: 60.00, fee_status: "unpaid", classLevel: "ACCA C1",course:"Acca" ,registeredBy:"cqani" }


let Class ={name:"",status:"" ,registeredDate: "14/2/2024",endDate: "14/2/2024",course:""}

let Classes = [
    class{},class{}
]
export const saveStudent = async (dataArray) => {
    try {
      // Get a reference to the public data collection
      const Students = collection(db, 'Students');
  
      // Add a new document with a generated ID
      await addDoc(Students, {
        data: dataArray // dataArray is your array of public data
      });
  
      console.log("Public data added successfully!");
    } catch (error) {
      console.error("Error adding Students data: ", error);
    }
};


export const getData = async () => {
    try {
        const Students = collection(db, 'Students');
        const querySnapshot = await getDocs(Students);
        let data = [];
        querySnapshot.docs.forEach(doc => {
          let transformedData = transformData(doc.data())
          data.push(transformedData);
      });
      console.log("Students data:", data); // Log the students array
      return data;
    } catch (error) {
        console.error("Error fetching Students data: ", error);
        return [];
    }
};
const transformData = (docData) => {
  return docData.data;
  
};

export const saveClass = async (Class) => {
  try {
    const courses = collection(db, "courses");
    const querySnapshot = await getDocs(courses);
    const courseData = querySnapshot.docs.map((doc) => doc.data());
    let course = courseData[0];

    if (!course.hasOwnProperty(Class.course)) {
      alert("the course you said save the class did not exist.")
    }

    course[Class.course].push({
      name: Class.name,
      status: Class.status,
      end_date: Class.end_date,
      start_date: Class.start_date,
    });

    // Update the document in Firestore
    const courseId = querySnapshot.docs[0].id; // Assuming you want to update the first document
    const courseRef = doc(db, "courses", courseId);
    await updateDoc(courseRef, course);

    alert("new course aded successfully!");
  } catch (error) {
    console.error("Error updating course data: ", error);
  }
};
export const getFirstCourseData = async () => {
  try {
    const courses = collection(db, "courses");
    const querySnapshot = await getDocs(courses);

    const firstDoc = querySnapshot.docs[0]; // Get the first document
    if (!firstDoc) {
      throw new Error("No course document found");
    }

    const courseId = firstDoc.id;
    const courseData = firstDoc.data();
    return courseData;
  } catch (error) {
    console.error("Error fetching course data: ", error);
    throw error;
  }
};

export const getGeneralDate = async () => {
  try {
    const general_date = []
    const Students = collection(db, 'Students');
    const querySnapshot = await getDocs(Students);
    const numberOfDocs = querySnapshot.size;
    general_date.push(numberOfDocs)
    const courses = collection(db, "courses");
    const query_course_Snapshot = await getDocs(courses);
    const firstDoc = query_course_Snapshot.docs[0];
    const courseData = firstDoc.data();
    const totalCousrses = Object.keys(courseData).length;
    const class_counts = Object.values(courseData).reduce((acc, curr) => acc + curr.length, 0);
    general_date.push(class_counts)
    general_date.push(totalCousrses)
    return general_date;
  } catch (error) {
    console.error("Error fetching Students data: ", error);
    throw error;
  }
};
export const storeUserData = async (user) => {
  try {
    const userRef = doc(db, "users", user.user_uid);
    await setDoc(userRef, {
      email: user.email,
      password: user.password,
      dashboardAccess: user.dashboardAccess,
      classesAccess: user.classesAccess,
      studentAccess: user.studentAccess,
      feeAndReports: user.feeAndReports,
      name: user.name,
      teacher: user.teacher || false,  
      admin: user.admin || false,      
      worker: user.worker || false     
    });
  } catch (error) {
    console.error("Error storing user data:", error);
  }
};


export const getAllUsers = async () => {
  const users = [];
  
  try {
    const querySnapshot = await getDocs(collection(db, "users"));
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      users.push({
        email: data.email,
        password: data.password,
        dashboardAccess: data.dashboardAccess,
        studentAccess: data.studentAccess,
        classesAccess: data.classesAccess,
        feeAndReports: data.feeAndReports,
        name: data.name,
        teacher:data.teacher,
        admin:data.admin,
        worker:data.worker
      });
    });
  } catch (error) {
    console.error("Error fetching user documents:", error);
  }

  return users;
};
export async function getUserById(uid) {
  try {
    const docRef = doc(db, 'users', uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null; // No matching document found
    }
  } catch (error) {
    console.error("Error getting document: ", error);
    throw error; // Rethrow the error for further handling
  }
}


export const saveCourse= async (course_name) => {
  try {
    // Reference to the 'courses' collection
    const coursesCollection = collection(db, 'courses');
    
    // Get the first document in the 'courses' collection
    const querySnapshot = await getDocs(coursesCollection);
    const firstDoc = querySnapshot.docs[0];
    const firstDocRef = doc(db, 'courses', firstDoc.id);
    await updateDoc(firstDocRef, {
      [course_name]: []
    });
    alert("we saved new course");
  } catch (error) {
    alert('Error saving new course');
  }
}
export const getCourses= async () => {
  try {
    // Reference to the 'courses' collection
    const coursesCollection = collection(db, 'courses');
    
    // Get the first document in the 'courses' collection
    const querySnapshot = await getDocs(coursesCollection);
    const firstDoc = querySnapshot.docs[0];
    
    if (!firstDoc) {
      throw new Error('No documents found in the collection');
    }

    // Get the document data
    const docData = firstDoc.data();

    // Return all keys and their values
    return docData;
  } catch (error) {
    console.error('Error retrieving document:', error);
  }
}
export async function findStudentByEmail(email) {
  try {
    // Reference to the collection
    const studentRef = collection(db, 'Students');

    // Retrieve all documents from the collection
    const querySnapshot = await getDocs(studentRef);

    // Check if documents are retrieved
    if (querySnapshot.empty) {
      console.log("No documents found in the collection.");
      return null;
    }

    // Iterate through all documents
    let studentDoc = null;
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      console.log('Document Data:', data); // Log each document's data
      if (data.data.email === email) {
        studentDoc = { id: doc.id, ...data }; // Match found, get the document ID and data
      }
    });

    if (!studentDoc) {
      console.log("No student found with the given email.");
    }

    return studentDoc;
  } catch (error) {
    console.error("Error fetching student:", error);
    throw error;
  }
}

export async function updateStudentData(docId, newData) {
  try {
    const studentDocRef = doc(db, "Students", docId);
    await updateDoc(studentDocRef, {
      data: newData
    });
    alert("Document successfully updated!");
    navigate("/admin/maps")
    console.log(docId)
  } catch (error) {
    console.error("Error updating document: ", error);
  }
}
export async function findusername() {
  try {
    let email = await getUserEmail();
    // Reference to the collection
    const studentRef = collection(db, 'users');

    // Retrieve all documents from the collection
    const querySnapshot = await getDocs(studentRef);

    // Check if documents are retrieved
    if (querySnapshot.empty) {
      console.log("No documents found in the collection.");
      return null;
    }

    // Iterate through all documents
    let studentDoc = null;
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      console.log('Document name:', data.name); // Log each document's data
      if (data.email === email) {
        studentDoc = data.name; // Match found, get the document ID and data
      }
    });

    if (!studentDoc) {
      console.log("No student found with the given email.");
    }

    return studentDoc;
  } catch (error) {
    console.error("Error fetching student:", error);
    throw error;
  }
}
export async function delete_user_data(email, password) {
  try {
    // Reference to the collection
    const userRef = collection(db, 'users');

    // Retrieve all documents from the collection
    const querySnapshot = await getDocs(userRef);

    // Check if documents are retrieved
    if (querySnapshot.empty) {
      console.log("No documents found in the collection.");
      return null;
    }

    // Iterate through all documents
    let userDeleted = false;
    for (const docSnapshot of querySnapshot.docs) {
      const data = docSnapshot.data();
      console.log('Document Data:', data); // Log each document's data
      if (data.email === email && data.password === password) {
        const userDocRef = doc(db, "users", docSnapshot.id);
        await deleteDoc(userDocRef); // Await the delete operation
        userDeleted = true;
        console.log(`User with email ${email} deleted successfully.`);
        break; // Exit loop after deletion
      }
    }

    if (!userDeleted) {
      console.log("No user found with the given email and password.");
    }

    return userDeleted;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
}

export async function deleteStudentByEmailAndNumber(email, number) {
  try {
    const studentsCollection = collection(db, "Students");
    const snapshot = await getDocs(studentsCollection);

    const deletePromises = snapshot.docs.map(async (docSnapshot) => {
      const studentData = docSnapshot.data().data;
      if (studentData.email === email && studentData.phoneNumber === number) {
        await deleteDoc(doc(db, "Students", docSnapshot.id));
        alert(`Deleted student with email: ${email} and number: ${number}`);
        return true;
      }
      return false;
    });

    const results = await Promise.all(deletePromises);
    const studentDeleted = results.includes(true);

    if (!studentDeleted) {
      alert(`No student found with email: ${email} and number: ${number}`);
    }
  } catch (error) {
    console.error("Error deleting student: ", error);
    alert(`An error occurred while deleting the student. ${error}`);
  }
}

export async function checkStudentExists(email, phone) {
  const studentsCollection = collection(db, "Students");
  const studentsSnapshot = await getDocs(studentsCollection);

  const exists = studentsSnapshot.docs.some(doc => {
    const data = doc.data().data;
    if (data && data.email === email && data.phoneNumber === phone) {
      alert(`There is already a student with email: ${email} and number: ${phone}`);
      return true;
    }
    return false;
  });

  return exists;
}
export async function updateFeeStatus() {
  console.log("hello somebdoy called me");
  
  try {
    const studentsCollection = collection(db, 'Students');
    const querySnapshot = await getDocs(studentsCollection);
    for (const docSnapshot of querySnapshot.docs) {
      
      const documentId = docSnapshot.id;
      const documentRef = doc(db, 'Students', documentId);
      const docData = docSnapshot.data();
      if (docData.data && docData.data.fee_status !== undefined) {
        await updateDoc(documentRef, {
          'data.fee_status': checkPaymentStatus(docData.data.lastPaidTime,docData.data.totalFee,docData.data.paidFee),
        });
      }
    }

    console.log('Fee status updated successfully.');
  } catch (error) {
    console.error('Error updating fee status: ', error);
  }
}
export function checkPaymentStatus(lastPaidTime, total_amount, paid_amount) {
  
  const paidAmount = paid_amount;
  const totalAmount = total_amount;

  if (isNaN(paidAmount) || isNaN(totalAmount)) {
    throw new Error('Invalid input: Both inputs must be numbers.');
  }

  let balance = totalAmount - paidAmount;
  const now = new Date();

  if (!lastPaidTime) {
    return "unpaid";
  }

  const lastPaidDate = new Date(lastPaidTime);

  const timeDifference = now - lastPaidDate;
  
  // Convert the time difference to days
  const daysSinceLastPayment = timeDifference / (1000 * 60 * 60 * 24);

  const balanceCondition = balance > 0;
  const dateCondition = daysSinceLastPayment > 30;

  if (balanceCondition && dateCondition) {
    return "unpaid";
  } else {
    return "paid";
  }
}



export const saveLeads = async (name, course, jsonLeads, Date) => {
  try {
    const blob = new Blob([jsonLeads], { type: 'application/json' });
    const baseFileName = `${name}_leads`;
    let newFileName = `${baseFileName}.json`;
    const storageRef = ref(storage, 'leads/');
    const list = await listAll(storageRef);
    const existingFiles = list.items.map(item => item.name);

    let counter = 1;

    while (existingFiles.includes(newFileName)) {
      newFileName = `${baseFileName}_${counter++}.json`;
    }

    const newStorageRef = ref(storage, `leads/${newFileName}`);
    const snapshot = await uploadBytes(newStorageRef, blob);
    const downloadURL = await getDownloadURL(snapshot.ref);

    const docRef = await addDoc(collection(db, 'Leads'), {
      name: name,
      course: course,
      fileURL: downloadURL,
      fileName: newFileName,
      timestamp: Date
    });

    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};



export const getLeads = async () => {
  try {
    const leadsCollection = collection(db, 'Leads');
    const leadsSnapshot = await getDocs(leadsCollection);
    const leadsList = leadsSnapshot.docs.map(doc => ({
      id: doc.id,  
      ...doc.data()
    }));

    return leadsList;
  } catch (e) {
    console.error("Error fetching leads: ", e);
    return [];
  }
};

export const getFileURLById = async (id) => {
  try {
    const docRef = doc(db, 'Leads', id);
    const docSnapshot = await getDoc(docRef);
    
    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      return data.fileURL;
    } else {
      console.error("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching document: ", error);
    return null;
  }
};

export async function saveLecture(lecture) {
  try {
      const docRef = await addDoc(collection(db, "Lectures"), {
          name: lecture.name,
          day: lecture.day,
          startTime: lecture.startTime,
          endTime: lecture.endTime,
          course: lecture.course,
          classLevel: lecture.classLevel,
          teacher: lecture.teacher,
      });

      console.log("Lecture saved with ID: ", docRef.id);
      return docRef.id;
  } catch (error) {
      console.error("Error adding lecture: ", error);
      throw error;
  }
}

export async function getTeachers() {
  try {
      const usersCollection = collection(db, 'users');
      const q = query(usersCollection, where('teacher', '==', true));
      const querySnapshot = await getDocs(q);
      const teachersArray = [];
      querySnapshot.forEach((doc) => {
          teachersArray.push({ id: doc.id, ...doc.data() });
      });
      return teachersArray;
  } catch (error) {
      console.error("Error fetching teachers: ", error);
      return [];
  }
}

export async function fetchLectures() {
  try {
      const lecturesCollection = collection(db, "Lectures");
      const querySnapshot = await getDocs(lecturesCollection);
      const lecturesArray = [];
      querySnapshot.forEach((doc) => {
          lecturesArray.push({ id: doc.id, ...doc.data() });
      });

      return lecturesArray;
  } catch (error) {
      console.error("Error loading lectures: ", error);
      throw error;
  }
}

export async function uploadFileToLeadsFolder(file) {
  if (!file) {
    throw new Error("No file provided");
  }

  try {
    const storageRef = ref(storage, `leads/${file.name}`);
    const snapshot = await uploadBytes(storageRef, file);
    console.log('File uploaded successfully:', snapshot.metadata.fullPath);
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
}

export const getFilenamebyId = async (id) => {
  try {
    const docRef = doc(db, 'Leads', id);
    const docSnapshot = await getDoc(docRef);
    
    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      return data.fileName;
    } else {
      console.error("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching document: ", error);
    return null;
  }
};
export async function saveAttendanceData(attendanceData) {
  const attendanceCollection = collection(db, 'attendance');

  try {
    const q = query(attendanceCollection, where('Lecture', '==', attendanceData.Lecture));
    const querySnapshot = await getDocs(q);
    const today = new Date();
    const dateKey = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

    if (!querySnapshot.empty) {
      querySnapshot.forEach(async (docSnapshot) => {
        const docRef = doc(db, 'attendance', docSnapshot.id);
        const updateData = {
          [dateKey]: {
            TotalStudents: attendanceData.TotalStudents,
            TotalPresents: attendanceData.TotalPresents,
            TotalAbsents: attendanceData.TotalAbsents,
            TotalLates: attendanceData.TotalLates,
            StudentAttendances: attendanceData.StudentAttendances
          }
        };
        await updateDoc(docRef, updateData);
        console.log('Attendance updated for:', attendanceData.Lecture);
      });
    } else {
      const newDocRef = doc(attendanceCollection);

      const newDocData = {
        Lecture: attendanceData.Lecture,
        [dateKey]: {
          TotalStudents: attendanceData.TotalStudents,
          TotalPresents: attendanceData.TotalPresents,
          TotalAbsents: attendanceData.TotalAbsents,
          TotalLates: attendanceData.TotalLates,
          StudentAttendances: attendanceData.StudentAttendances
        }
      };
      await setDoc(newDocRef, newDocData);
      console.log('New attendance document created for:', attendanceData.Lecture);
    }
  } catch (error) {
    console.error('Error saving attendance data:', error);
  }
}

export async function getAllAttendanceDocs() {
  try {
    const attendanceCollection = collection(db, 'attendance');
    const attendanceSnapshot = await getDocs(attendanceCollection);
    const attendanceList = attendanceSnapshot.docs.map(doc => ({
      id: doc.id, 
      ...doc.data()
    }));

    // Logging all documents retrieved
    console.log('Attendance Documents:', attendanceList);
    return attendanceList;
  } catch (error) {
    console.error('Error retrieving attendance documents:', error);
  }
}

export const deleteClassByCourseAndName = async (courseName, className) => {
  try {
    const coursesCollection = collection(db, "courses");
    const querySnapshot = await getDocs(coursesCollection);
    const firstDoc = querySnapshot.docs[0]; 
    if (!firstDoc) {
      throw new Error("No course document found");
    }

    const courseId = firstDoc.id;
    const courseData = firstDoc.data();

    if (!courseData.hasOwnProperty(courseName)) {
      alert(`Course ${courseName} does not exist.`);
      return;
    }

    const classes = courseData[courseName];

    const updatedClasses = classes.filter((Class) => Class.name !== className);

    if (classes.length === updatedClasses.length) {
      alert(`Class ${className} not found in course ${courseName}.`);
      return;
    }

    courseData[courseName] = updatedClasses;
    const courseRef = doc(db, "courses", courseId);
    await updateDoc(courseRef, courseData);

    alert(`Class ${className} deleted successfully from course ${courseName}.`);
  } catch (error) {
    console.error("Error deleting class: ", error);
    alert(`An error occurred while deleting the class. ${error}`);
  }
};