<script>
  import { link } from "svelte-routing";
  import { authHandlers } from "../src/store/store";

  let email = '';
  let password = '';
  let errorMessage = '';
  export let location;

  async function handleLogin() {
      if (!email || !password) {
          errorMessage = "Email and password are required.";
          return;
      } 

      try {
          await authHandlers.login(email, password);
          save_last_date();
      } catch (err) {
          errorMessage = "There was a problem with the login. Please try again.";
          console.log("Login error:", err);
      }
  }

  function save_last_date() {
      const currentDate = new Date();
      localStorage.setItem('date', currentDate);
  }
</script>

<div class="min-h-screen flex items-center justify-center bg-gray-100">
  <div class="w-full max-w-md bg-white rounded-lg shadow-md overflow-hidden">
    <div class="p-8 space-y-6">
      
      <!-- Centered logo (replace with your actual logo or text) -->
      <div class="flex justify-center">
        <img src="assets/img/logo.png" alt="Logo" class="h-12 w-auto">
      </div>

      <!-- Sign in with credentials -->
      <h2 class="text-center text-2xl font-bold text-gray-700">Sign in with credentials</h2>

      <form>
        <!-- Email Field -->
        <div class="relative w-full mb-4">
          <label class="block text-gray-700 font-semibold mb-2" for="grid-email">
            Email
          </label>
          <input
            id="grid-email"
            type="email"
            class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            placeholder="Email"
            bind:value={email}
          />
        </div>

        <!-- Error message -->
        

        <!-- Password Field -->
        <div class="relative w-full mb-6">
          <label class="block text-gray-700 font-semibold mb-2" for="grid-password">
            Password
          </label>
          <input
            id="grid-password"
            type="password"
            class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            placeholder="Password"
            bind:value={password}
          />
        </div>
        {#if errorMessage}
          <p class="text-red-500 text-sm text-center">{errorMessage}</p>
        {/if}

        <!-- Sign In Button -->
        <div class="text-center">
          <button
            style="
              width: 100%; 
              background-color: #16a34a; 
              color: white; 
              font-weight: 600; 
              padding: 0.5rem 1rem; 
              border-radius: 0.375rem; 
              cursor: pointer; 
              outline: none;
              transition: background-color 0.3s ease;"
            type="button"
            onmouseover="this.style.backgroundColor='#4f46e5';" 
            onmouseout="this.style.backgroundColor='#16a34a';"
            on:click={handleLogin}
          >
            Sign In
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
