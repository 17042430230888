// Import the functions you need from the SDKs you need
import { deleteApp, getApp, getApps, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage} from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCamLdKBdAOPTImK1dOks0sJytffwY1onI",
  authDomain: "uls-admin.firebaseapp.com",
  projectId: "uls-admin",
  storageBucket: "uls-admin.appspot.com",
  messagingSenderId: "314749094114",
  appId: "1:314749094114:web:4a29cee640f757c0ddb6bb"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
let firebaseApp;
if (!getApps().length){
    firebaseApp = initializeApp(firebaseConfig)
} else {
    firebaseApp = getApp()
    deleteApp(firebaseApp)
    firebaseApp = initializeApp(firebaseConfig)
}

export const db = getFirestore(firebaseApp)
export const auth = getAuth(firebaseApp)
export const storage = getStorage(app);