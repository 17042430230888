<script>
    import { getFirstCourseData, saveLecture, getTeachers } from "../../store/db";
    import { onMount } from 'svelte';
    import { getUserEmail } from "../../store/store";

    let email = '';
    let lecture = {
        name: "",
        day: "",
        startTime: "",
        endTime: "",
        course: "",
        classLevel: "",
        teacher: "",
        registeredBy: ""
    };

    let courses = {
        English: [
            { name: "Elementary C1", classes: 20 },
            { name: "Intermediate C2", classes: 18 },
            { name: "Advanced C3", classes: 22 }
        ],
        Math: [
            { name: "Algebra", classes: 10 },
            { name: "Calculus", classes: 15 }
        ]
    };

    let daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    let teachers = []; // Initialize as an empty array

    async function addLecture() {
        // Check if all required fields are filled
        if (
            !lecture.name ||
            !lecture.day ||
            !lecture.startTime ||
            !lecture.endTime ||
            !lecture.course ||
            !lecture.classLevel ||
            !lecture.teacher
        ) {
            alert("Please fill in all fields.");
            return;
        }

        lecture.registeredBy = email;
        console.log("Saving lecture:", lecture);  // Debugging log before saving
        try {
            await saveLecture(lecture);
            alert("Lecture saved successfully.");
            lecture = { name: "", day: "", startTime: "", endTime: "", course: "", classLevel: "", teacher: "", registeredBy: "" };   
        } catch (error) {
            console.error("Failed to save lecture:", error);
            alert("Failed to save lecture.");
        }
    }

    async function fetchData() {
        try {
            const classData = await getFirstCourseData();
            courses = classData;
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }

    async function fetchTeachers() {
        try {
            teachers = await getTeachers(); // Fetch teachers from Firestore
        } catch (error) {
            console.error("Error fetching teachers: ", error);
        }
    }

    onMount(async () => {
        try {
            email = await getUserEmail();
        } catch (error) {
            console.error(error);
        }
        fetchData();
        fetchTeachers(); // Fetch teachers on mount
    });
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
            <h6 class="text-blueGray-700 text-xl font-bold">New Lecture</h6>
            <button on:click={addLecture} class="bg-red-400 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">
                Add
            </button>
        </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form>
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">Lecture Information</h6>
            <div class="flex flex-wrap">
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Lecture Name</label>
                        <input type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="Lecture Name" bind:value={lecture.name} />
                    </div>
                </div>

                <div class="w-full lg:w-6/12 px-4">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Day of the Week</label>
                    <select bind:value={lecture.day} class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                        <option value="" disabled>-- Choose Day --</option>
                        {#each daysOfWeek as day}
                            <option value={day}>{day}</option>
                        {/each}
                    </select>
                </div>

                <div class="w-full lg:w-6/12 px-4">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Start Time</label>
                    <input type="time" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" bind:value={lecture.startTime} />
                </div>

                <div class="w-full lg:w-6/12 px-4">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">End Time</label>
                    <input type="time" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" bind:value={lecture.endTime} />
                </div>

                <div class="w-full lg:w-6/12 px-4">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Course</label>
                    <select bind:value={lecture.course} class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                        <option value="">-- Choose Course --</option>
                        {#each Object.keys(courses) as course}
                            <option value={course}>{course}</option>
                        {/each}
                    </select>
                </div>

                <div class="w-full lg:w-6/12 px-4">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Class Level</label>
                    <select bind:value={lecture.classLevel} class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                        {#if courses[lecture.course]}
                            <option value="">-- Select Class --</option>
                            {#each courses[lecture.course] as classItem}
                                <option value={classItem.name}>{classItem.name}</option>
                            {/each}
                        {:else}
                            <option disabled>-- Select Course --</option>
                        {/if}
                    </select>
                </div>

                <div class="w-full lg:w-6/12 px-4">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Teacher</label>
                    <select bind:value={lecture.teacher} class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                        <option value="">-- Choose Teacher --</option>
                        {#each teachers as teacher}
                            <option value={teacher.name}>{teacher.name}</option>
                        {/each}
                    </select>
                </div>
            </div>
        </form>
    </div>
</div>
