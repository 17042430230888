import { createUserWithEmailAndPassword,signInWithEmailAndPassword ,signOut,onAuthStateChanged} from "firebase/auth";
import { getAuth } from "firebase/auth";
import { writable } from "svelte/store";
import { auth } from "../lib/firebase/firebase";

export const authStore = writable({
    user: null,
    loading: true,
    date: {}
});

export const authHandlers = {
    login: async(email, password) => {
        await signInWithEmailAndPassword(auth, email, password);
    },
    logout: async() => {
        await signOut(auth);
    },
    createUser: async (email, password) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const newUser = userCredential.user;
            console.log("User registered successfully with UID:", newUser.uid);
        } catch (error) {
            console.error("Error registering user:", error.message);
            // Handle error
        }
    }
};

export function printLoggedInUserUID() {
    return new Promise((resolve, reject) => {
      const auth = getAuth();
      
      onAuthStateChanged(auth, (user) => {
        if (user) {
          resolve(user.uid);
        } else {
          reject("No user is currently logged in.");
        }
      });
    });
  }

  export function getUserEmail() {
    const auth = getAuth();
    return new Promise((resolve, reject) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          resolve(user.email);
        } else {
          reject('No user is logged in');
        }
      });
    });
  }