<script>
  let query = '';
  let filteredStudents = [];
  import { onMount } from 'svelte';
  import { getData, findusername ,findStudentByEmail,updateStudentData} from "../../store/db";
  import { getUserEmail } from "../../store/store";
  let email = '';
  let student_id = ""
let paidamount = 0
  let choosed_student = {
      name: "",
      status: "active",
      phoneNumber: "",
      email: "",
      registeredDate: "",
      lastPaidTime: "",
      lastPaidAmount: 0.00,
      paidFee: 0.0,
      totalFee: 0.00,
      fee_status: "paid", // Set fee_status to "paid"
      classLevel: "",
      Course: "",
      registeredBy: ""
    };
  let students = [
    { name: "Cqani Cali", registeredDate: "14/2/2024", phoneNumber: "+252 61 123 4567", email: "cqani@example.com" },
    { name: "Faarax Warsame", registeredDate: "20/2/2024", phoneNumber: "+252 61 234 5678", email: "faarax@example.com" },
    { name: "Farxiyo Cabdiqaadir Warsame", registeredDate: "11/4/2024", phoneNumber: "+252 61 345 6789", email: "farxiyo@example.com" },
    { name: "Ayaan Maxamed Faarax", registeredDate: "1/2/2024", phoneNumber: "+252 61 456 7890", email: "ayaan@example.com" },
    { name: "Cabdille Shiine", registeredDate: "1/2/2024", phoneNumber: "+252 61 567 8901", email: "cabdille@example.com" }
  ];
  $: {
        
  }
  async function fetchData() {
    try {
      const data = await getData();
      students = data
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }

  onMount(async () => {
    try {
      email = await findusername();
    } catch (error) {
      console.error(error);
    }
    fetchData();  
  });

  $: filteredStudents = query.trim() === '' ? [] : students.filter(student => {
    const nameWithoutSpaces = student.name.replace(/\s+/g, '').toLowerCase();
    const queryWithoutSpaces = query.replace(/\s+/g, '').toLowerCase();
    const phoneNumber = student.phoneNumber ? student.phoneNumber.toString().replace(/\s+/g, '') : ''; // Check if phoneNumber is truthy and convert to string
    return nameWithoutSpaces.includes(queryWithoutSpaces) || phoneNumber.includes(queryWithoutSpaces);
  });

  function filterAndPrint() {
    const buttonContainer = document.getElementById('button-container');
    buttonContainer.innerHTML = '';
    if (query.trim() === '') return;
    if (filteredStudents.length === 0) return;
    filteredStudents.forEach(student => {
      const button = document.createElement('button');
      button.textContent = `${student.name} - ${student.phoneNumber}`;
      button.setAttribute('class', 'border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150');
      button.addEventListener('click', function(event) {
        event.preventDefault();
        document.getElementById('searchQuery').value = `${student.name} - ${student.phoneNumber}`;
        choosed_student = student; // Update choosed_student with selected student's data
        hideFilterButtons();
      });
      buttonContainer.appendChild(button);
    });
  }

  function hideFilterButtons() {
    const buttonContainer = document.getElementById('button-container');
    buttonContainer.innerHTML = '';
  }
  async function save_info() {
  if (paidamount !== 0 || choosed_student.name !== "") {
    try {
      const studentDoc = await findStudentByEmail(choosed_student.email);
      if (studentDoc) {
        const student_id = studentDoc.id;
        choosed_student.paidFee = Number(choosed_student.paidFee) + Number(paidamount);
        console.log(choosed_student);
        await updateStudentData(student_id, choosed_student);
      } else {
        console.log("No student found with the given email", choosed_student.email);
      }
    } catch (error) {
      console.error("Error finding student:", error);
    }
  }
}
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
  <div class="rounded-t bg-white mb-0 px-6 py-6 ">
    <div class="text-center flex justify-between">
      <h6 class="text-blueGray-700 text-xl font-bold">New student</h6>
      <button on:click={save_info} class="bg-red-400 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">
        Add it
      </button>
    </div>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form>
      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        Student information
      </h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="searchQuery">
              Paid amount
            </label>
            <input
              type="number"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              placeholder="27.5"
              bind:value={paidamount}
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="date"
            >
            paid date
            </label>
            <input
              id="date"
              type="date"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              placeholder="elemantry english"
              bind:value={choosed_student.lastPaidTime}
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="grid-email"
            >
            Students info
            </label>
            <input
            autocomplete="off"
            id="searchQuery"
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              placeholder="Enter student number or email"
              bind:value={query}
              on:input={filterAndPrint}
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="grid-email"
            >
              registered by
            </label>
            <div
              id="registeredBy"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            >
              {email || 'Loading...'}
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3" id="button-container">
            <!-- Buttons will be dynamically added here -->
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
