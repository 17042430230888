<script>
  import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
  import { getFirestore, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
  import { storeUserData, getAllUsers ,delete_user_data} from "../../store/db";
  
  export let color = "light";
  export let isAdmin = false;

  let options = ["yes", "no"]; // For general access fields
  let users = [];
  let newUserEmail = "";
  let newUserPassword = "";
  let newUserName = "";

  const auth = getAuth();
  const db = getFirestore();

  const fetchUsers = async () => {
    try {
      users = await getAllUsers();
      console.log(users);
      
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  fetchUsers();

  function handleChange(user, key, event) {
  const newValue = event.target.value;

  // If the key is one of the boolean fields, convert it to a boolean
  if (key === 'teacher' || key === 'admin' || key === 'worker') {
    user[key] = newValue === 'true'; // Convert string to boolean
  } else {
    user[key] = newValue; // For other fields, just set the value
  }

  users = [...users]; 
}


  function isUserDataChanged(user) {
    const originalUser = users.find(u => u.email === user.email);
    return originalUser && Object.keys(user).some(key => user[key] !== originalUser[key]);
  }

  async function updateUser(user) {
  try {
    const usersCollection = collection(db, 'users');
    const q = query(usersCollection, where('email', '==', user.email), where('password', '==', user.password));

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      const userRef = userDoc.ref;

      await updateDoc(userRef, {
        studentAccess: user.studentAccess,
        feeAndReports: user.feeAndReports,
        dashboardAccess: user.dashboardAccess,
        classesAccess: user.classesAccess,
        teacher: user.teacher === "true" || user.teacher === true, 
        admin: user.admin === "true" || user.admin === true,      
        worker: user.worker === "true" || user.worker === true 
      });

      alert('User access updated successfully.');
    } else {
      alert('No user found with the given email and password.');
    }
  } catch (error) {
    console.error('Error updating user access: ', error);
  }
}


  async function addNewUser(email, password, name) {
    const currentUser = auth.currentUser;
    const currentUserEmail = currentUser.email;
    const currentUserPassword = prompt('Please re-enter your password to create a new user:');

    let newUser = {
      name: name,
      user_uid: "",
      email: email,
      password: password,
      dashboardAccess: "no",
      studentAccess: "yes",
      classesAccess: "no",
      feeAndReports: "no",
      teacher: false,
      admin: false,
      worker: false
    };

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      newUser.user_uid = userCredential.user.uid;

      await signInWithEmailAndPassword(auth, currentUserEmail, currentUserPassword);
      alert('Created new user.');
      await storeUserData(newUser);

      fetchUsers();
    } catch (error) {
      console.error('Error creating new user or re-authenticating admin:', error);
    }
  }

  async function delete_user(email, password) {
    const currentUser = auth.currentUser;
    const currentUserEmail = currentUser.email;
    const currentUserPassword = prompt('Please re-enter your password to delete the user:');
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await user.delete();
      await signInWithEmailAndPassword(auth, currentUserEmail, currentUserPassword);
      delete_user_data(email, password);
      alert("User deleted successfully.");
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  }
</script>

{#if users.length > 0}
<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">
  <div class="block w-full overflow-x-auto">
    <table class="items-center w-full bg-transparent border-collapse">
      <thead>
        <tr>
          <th class="px-6 align-middle py-3 text-xs uppercase whitespace-nowrap font-bold text-left">Name</th>
          <th class="px-6 align-middle py-3 text-xs uppercase whitespace-nowrap font-bold text-left">Email</th>
          <th class="px-6 align-middle py-3 text-xs uppercase whitespace-nowrap font-bold text-left">Password</th>
          <th class="px-6 align-middle py-3 text-xs uppercase whitespace-nowrap font-bold text-left">Dashboard Access</th>
          <th class="px-6 align-middle py-3 text-xs uppercase whitespace-nowrap font-bold text-left">Student Access</th>
          <th class="px-6 align-middle py-3 text-xs uppercase whitespace-nowrap font-bold text-left">Classes Access</th>
          <th class="px-6 align-middle py-3 text-xs uppercase whitespace-nowrap font-bold text-left">Fee and Reports</th>
          <th class="px-6 align-middle py-3 text-xs uppercase whitespace-nowrap font-bold text-left">Teacher</th>
          <th class="px-6 align-middle py-3 text-xs uppercase whitespace-nowrap font-bold text-left">Admin</th>
          <th class="px-6 align-middle py-3 text-xs uppercase whitespace-nowrap font-bold text-left">Worker</th>
          <th class="px-6 align-middle py-3 text-xs uppercase whitespace-nowrap font-bold text-left"></th>
        </tr>
      </thead>
      <tbody>
        {#each users as user, index}
        <tr>
          <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left flex items-center font-bold">{user.name || "admin"}</td>
          <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap ">{user.email}</td>
          <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap ">{user.password}</td>
          <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap">
            <select bind:value={user.dashboardAccess} on:change={e => handleChange(user, 'dashboardAccess', e)}>
              {#each options as option}
                <option value={option}>{option}</option>
              {/each}
            </select>
          </td>
          <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap">
            <select bind:value={user.studentAccess} on:change={e => handleChange(user, 'studentAccess', e)}>
              {#each options as option}
                <option value={option}>{option}</option>
              {/each}
            </select>
          </td>
          <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            <select bind:value={user.classesAccess} on:change={e => handleChange(user, 'classesAccess', e)}>
              {#each options as option}
                <option value={option}>{option}</option>
              {/each}
            </select>
          </td>
          <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            <select bind:value={user.feeAndReports} on:change={e => handleChange(user, 'feeAndReports', e)}>
              {#each options as option}
                <option value={option}>{option}</option>
              {/each}
            </select>
          </td>
          <!-- Boolean fields for teacher, admin, worker -->
          <td>
            <select bind:value={user.teacher} on:change={e => handleChange(user, 'teacher', e)}>
              <option value={true}>true</option>
              <option value={false}>false</option>
            </select>
          </td>
          <td>
            <select bind:value={user.admin} on:change={e => handleChange(user, 'admin', e)}>
              <option value={true}>true</option>
              <option value={false}>false</option>
            </select>
          </td>
          <td>
            <select bind:value={user.worker} on:change={e => handleChange(user, 'worker', e)}>
              <option value={true}>true</option>
              <option value={false}>false</option>
            </select>
          </td>
          <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {#if !isAdmin}
              <button on:click={() => updateUser(user)} class="{isUserDataChanged(user) ? 'bg-indigo-500 opacity-100' : 'bg-indigo-500 opacity-70'} text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                Save
              </button>
              <button on:click={() => delete_user(user.email, user.password)} class="{isUserDataChanged(user) ? 'bg-indigo-500 opacity-100' : 'bg-indigo-500 opacity-70'} text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                Delete
              </button>
            {/if}
          </td>
        </tr>
        {/each}
        {#if !isAdmin}
        <tr>
          <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            <input type="text" placeholder="Name" bind:this={newUserName}>
          </td>
          <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            <input type="text" placeholder="Email" bind:this={newUserEmail}>
          </td>
          <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            <input type="password" placeholder="Password" bind:this={newUserPassword}>
          </td>
          <td colspan="4" class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            <button on:click={() => addNewUser(newUserEmail.value, newUserPassword.value, newUserName.value)} class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
              New User
            </button>
          </td>
        </tr>
        {/if}
      </tbody>
    </table>
  </div>
</div>
{/if}
