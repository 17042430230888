<script>
  import { createPopper } from "@popperjs/core";
  import { navigate } from "svelte-routing";
  import { deleteStudentByEmailAndNumber, findStudentByEmail, updateStudentData } from "../../store/db";

  export let student = {
    name: "",
    status: "active",
    phoneNumber: "",
    email: "",
    registeredDate: "",
    lastPaidTime: "",
    lastPaidAmount: 0.00,
    paidFee: 0.0,
    totalFee: 0.00,
    fee_status: "paid",
    classLevel: "",
    Course: "",
    registeredBy: ""
  };

  let dropdownPopoverShow = false;
  let btnDropdownRef;
  let popoverDropdownRef;

  const toggleDropdown = (event) => {
    event.preventDefault();
    dropdownPopoverShow = !dropdownPopoverShow;
    if (dropdownPopoverShow) {
      createPopper(btnDropdownRef, popoverDropdownRef, {
        placement: "bottom-start",
      });
    }
  };

  let showModal = false;
  let selectedReason = "";
  let modalTop = "50%";
  let modalLeft = "50%";
  let modalZIndex = 9999;
  let reasons = ["Graduated", "Left (No idea)","removed", "Other"];

  $: statusText = student.status === "active" ? "Deactivate student" : "Activate student";

  const handleEditStudentClick = (event) => {
    event.preventDefault();
    if (student.email && student.phoneNumber) {
      navigate(`/admin/edit/${student.email}/${student.phoneNumber}`);
    } else {
      alert("Student email or phone number is missing.");
    }
  };

  const handledelteClick = (event) => {
    event.preventDefault();
    if (student.email && student.phoneNumber) {
      deleteStudentByEmailAndNumber(student.email, student.phoneNumber);
    } else {
      alert("Student email or phone number is missing.");
    }
  };

  const handleDeactivate = (event) => {
    event.preventDefault();
    if (student.status === "active") {
      showModal = true; // Show the modal window if student is active
    } else {
      toggleStudentStatus();
    }
  };

  const toggleStudentStatus = () => {
    if (student.email && student.phoneNumber) {
      findStudentByEmail(student.email)
        .then((studentDoc) => {
          if (studentDoc) {
            student.status = student.status === "active" ? "deactive" : "active";
            if (selectedReason) {
              student.reason = selectedReason; // Save the selected reason
            }
            updateStudentData(studentDoc.id, student);
            alert(statusText);
            dropdownPopoverShow = false;
            showModal = false; // Close modal on successful update
          } else {
            console.log("No student found with the given email", student.email);
          }
        })
        .catch((error) => {
          console.error("Error finding student:", error);
        });
    } else {
      alert("Student email or phone number is missing.");
    }
  };

  const handleSaveReason = () => {
    if (selectedReason) {
      toggleStudentStatus();
    } else {
      alert("Please select a reason.");
    }
  };

  const handleCancel = () => {
    showModal = false; // Hide the modal window
  };

  let dragStartX = 0;
  let dragStartY = 0;
  let modalOffsetX = 0;
  let modalOffsetY = 0;
  let isDragging = false;

  // Limit dragging to the modal header
  const handleDragStart = (event) => {
    // Only start dragging if the click is on the header
    if (event.target.classList.contains('modal-header')) {
      dragStartX = event.clientX;
      dragStartY = event.clientY;
      const modalRect = event.target.closest('.modal').getBoundingClientRect();
      modalOffsetX = dragStartX - modalRect.left;
      modalOffsetY = dragStartY - modalRect.top;
      isDragging = true;

      // Listen for movement and end on the window, not the modal
      window.addEventListener('mousemove', handleDragMove);
      window.addEventListener('mouseup', handleDragEnd);
    }
  };

  const handleDragMove = (event) => {
    if (isDragging) {
      modalLeft = `${event.clientX - modalOffsetX}px`;
      modalTop = `${event.clientY - modalOffsetY}px`;
    }
  };

  const handleDragEnd = () => {
    isDragging = false;
    window.removeEventListener('mousemove', handleDragMove);
    window.removeEventListener('mouseup', handleDragEnd);
  };
</script>

<!-- Dropdown Menu -->
<div>
  <a
    class="text-blueGray-500 py-1 px-3"
    href="#pablo"
    bind:this={btnDropdownRef}
    on:click={toggleDropdown}
  >
    <i class="fas fa-ellipsis-v"></i>
  </a>
  <div
    bind:this={popoverDropdownRef}
    class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 {dropdownPopoverShow ? 'block' : 'hidden'}"
  >
    <a
      href="#pablo"
      on:click={handleEditStudentClick}
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
    >
      Edit student data
    </a>
    <a
      href="#pablo"
      on:click={handledelteClick}
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
    >
      Delete user
    </a>
    <a
      href="#pablo"
      on:click={handleDeactivate}
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
    >
      {statusText}
    </a>
  </div>
</div>

<!-- Modal Window for Deactivation Reason -->
{#if showModal}
  <div
    class="fixed top-0 left-0 w-full h-full p-4"
    style="background-color: rgba(0, 0, 0, 0.5); z-index: {modalZIndex}; display: flex; align-items: center; justify-content: center;"
  >
    <div
      class="bg-white rounded-lg shadow-lg p-10 max-w-sm modal"
      style="position: absolute; top: {modalTop}; left: {modalLeft}; z-index: {modalZIndex};"
    >
      <!-- Modal header is the draggable area -->
      <div class="modal-header" style="cursor: move;" on:mousedown={handleDragStart}>
        <h3 class="text-xl font-semibold p-4">Deactivate <span style="color: red;">{student.name}</span></h3>
      </div>

      <div class="mt-4 p-4">
        <select id="statusSelector" bind:value={selectedReason} class="form-select w-full mt-2 p-2 border border-gray-300 rounded">
          <option value="" disabled selected>Select a reason</option>
          {#each reasons as reason}
            <option value={reason}>{reason}</option>
          {/each}
        </select>
      </div>

      <div class="flex justify-end gap-4 p-4">
        <button class="bg-red-500 text-white px-4 py-2 rounded" on:click={handleSaveReason}>Save</button>
        <button class="bg-gray-300 text-black px-4 py-2 rounded" on:click={handleCancel}>Cancel</button>
      </div>
    </div>
  </div>
{/if}
