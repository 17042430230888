<script>
  import { onMount } from 'svelte';
  import { getLeads } from "../../store/db"; 
  import { collection, deleteDoc, doc } from "firebase/firestore";
  import { ref, deleteObject } from "firebase/storage"; 
  import { db, storage } from "../../lib/firebase/firebase";
  let leads = [];

  // Fetch leads data on mount
  async function fetchLeads() {
    try {
      const data = await getLeads();
      leads = data;
      console.log("Fetched leads:", leads);
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  }

  onMount(() => {
    fetchLeads();
  });

  // Download function for file
  function downloadFile(url) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  // Delete lead function
  async function deleteLead(lead) {
    try {
      // Delete the document from Firestore
      await deleteDoc(doc(db, 'Leads', lead.id));

      // Delete the file from Firebase Storage using fileName
      const storageRef = ref(storage, `leads/${lead.fileName}`);  // Use fileName stored in Firestore
      await deleteObject(storageRef);

      // Remove the lead from the leads array
      leads = leads.filter(l => l.id !== lead.id);
      console.log(`Deleted lead ${lead.id} and file ${lead.fileName} from storage.`);
    } catch (error) {
      console.error("Error deleting lead:", error);
    }
  }
</script>

<div class="container mx-auto px-4 mb-16">
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-lg text-blueGray-700">All Leads</h3>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
              Lead Name
            </th>
            <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
              Course
            </th>
            <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
              Timestamp
            </th>
            <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"></th>
          </tr>
        </thead>
        <tbody>
          {#each leads as lead, index}
            <tr>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                <a class="ml-3 font-bold text-blueGray-600" href={`edit_leads/${lead.id}`}>
                  {index + 1}: {lead.name}
                </a>
              </td>              
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{lead.course}</td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{lead.timestamp}</td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                <a 
                href={`edit_leads/${lead.id}`} 
                style="background-color: #5a67d8; color: white; padding: 0.5rem 1rem; border-radius: 0.25rem; cursor: pointer; text-decoration: none;">
                Management
              </a>              
                <button 
                  style="background-color: #e53e3e; color: white; padding: 0.5rem 1rem; border-radius: 0.25rem; margin-left: 0.5rem; cursor: pointer;" 
                  on:click={() => deleteLead(lead)}>
                  Delete
                </button>
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
    </div>
  </div>
</div>
