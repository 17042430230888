<script>
    // core components
    import CardSettings from "../../components/Cards/Fee_registration.svelte";
    import { checkPermissions } from "../../lib/checkPermissions";
    import { onMount } from "svelte";
  let hasAccess = false;
  onMount(async () => {
    hasAccess = await checkPermissions('feeRegistration', '/admin/maps');
  });
    export let location;
  </script>
  {#if hasAccess}
  <div class="flex flex-wrap justify-content: center">
    <div class="w-full lg:w-8/12 px-4">
      <CardSettings />
    </div>
    
  </div>
  {/if}
  