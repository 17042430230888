<!-- App.svelte -->
<script>
  import { onMount } from "svelte";
  import { Router, Route, navigate } from "svelte-routing";
  import { auth } from "../src/lib/firebase/firebase";
  import { writable } from 'svelte/store';
  export const intervalActive = writable(false);

  let interval;
  const NonAuthRoutes = ["/"];
  function startInterval() {
    if (!interval) {
      interval = setInterval(() => {
        checkLogining();
      }, 60000); // Check every minute
      intervalActive.set(true);
    }
  }

  function checkLogining() {
    const currentDate = new Date();
    let savedDate = localStorage.getItem('date');
    const lastDate = new Date(savedDate);
    // @ts-ignore
    const differenceMs = currentDate - lastDate;
    const differenceMinutes = Math.floor(differenceMs / (1000 * 60));
    if (differenceMinutes >= 30) {
      auth.signOut(); // Log out user after 30 minutes
    } else {
      console.log("Difference is less than 30 minutes.", differenceMinutes);
    }
  }

  
  onMount(() => {
    startInterval();
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      const currentPath = window.location.pathname;

      if (user) {
        // User is signed in
        if (currentPath === "/") {
          navigate("/admin");
          return;
        }
      } else {
        // User is signed out
        if (!NonAuthRoutes.includes(currentPath)) {
          navigate("/");
        }
      }
    });

    return unsubscribe;
  });

  // Admin Layout
  import Admin from "./layouts/Admin.svelte";
  import Login from "./login.svelte";

  export let url = "";
  let title = "u-learns";
</script>

<head>
  <title>{title}</title>
</head>

<Router url="{url}">
  <!-- Admin layout -->
  <Route path="admin/*admin" component="{Admin}" />
  <!-- Auth layout -->
  <Route path="/" component="{Login}" />
</Router>
